import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { GET_CPD_LEVEL_DATE } from "./services/graphql";
import { AuthContext } from "../../../services/auth/AuthProvider";
import CpdContent from "./CpdContent";

const Cpd = ({ userId }) => {

    const { currentUser } = useContext(AuthContext);
    const { data } = useQuery(GET_CPD_LEVEL_DATE, {
        variables: {
            id: userId ? userId : currentUser.id,
        }
    });

    if (data?.user?.id) {
        const levelDate = userId ? data.user.practitionerLevelDate : currentUser.practitionerLevelDate;
        return <CpdContent fromView={userId} userId={userId ? userId : currentUser.id} levelDate={levelDate} />
    }
    return null;
}

export default Cpd;