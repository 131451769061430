import React, { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import "react-datepicker/dist/react-datepicker.css";
import FormErrors from '../../../../components/FormErrors';
import { CREATE_CIRCLE } from '../../services/grapqhl';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { CIRCLE_MODE } from '../../../../utils/constants';
import { Info, X } from 'react-feather';
import styles from './AddCase.module.scss';

const CaseStepCreate = ({ onCancel, toLeader, officeId = null }) => {

    const { currentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [showHelp, setShowHelp] = useState(false);

    // if Leader is creating cases (from My Cases page), check if they are a leader in 1 office only and add the case for that office,
    // otherwise, show a office selector for Leaders

    const leaderOfficeMemberships = toLeader?.officeMemberships?.filter(om => om.isCircleLeader);

    const [createCircle, { loading }] = useMutation(CREATE_CIRCLE, {
        onCompleted: data => {
            if (data.createCircle.errors && data.createCircle.errors.length > 0) {
                setFormErrors(data.createCircle.errors);
                return;
            }
            if (data.createCircle.circle && data.createCircle.circle.id) {
                if (toLeader) {
                    navigate(`/cases/${data.createCircle.circle.id}`)
                } else {
                    navigate(`/offices/${officeId}/cases/${data.createCircle.circle.id}`);
                }
            }
        },
        awaitRefetchQueries: true,
    });

    const schema = Yup.object().shape({
        referralSourceId: Yup.string().required('Referral source is required'),
        name: Yup.string().required('Name is required'),
        mode: Yup.string().required('Please select a case mode'),
        caseType: Yup.string().when('mode', {
            is: (value) => +value !== CIRCLE_MODE.mentoring,
            then: Yup.string().required('Case type is required'),
            otherwise: Yup.string(),
        }),
        ...(leaderOfficeMemberships?.length > 1 && { officeForLeader: Yup.string().required('Please select an office') }),
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {
            errors,
        },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        defaultValues: {
            caseManagementOnly: false
        }
    });



    const watchMode = watch('mode');
    const watchManagement = watch('caseManagementOnly');

    useEffect(() => {
        if (+watchMode === CIRCLE_MODE.restorative || +watchMode === CIRCLE_MODE.mediation) {
            setValue('caseManagementOnly', true);
        }
    }, [watchMode, setValue]);

    const onSubmit = data => {
        const { mode, caseType, name, officeForLeader, initiativeId, referralSourceId, otherId, conductCircleVirtually, caseManagementOnly } = data;
        const leaderHasOneOffice = (leaderOfficeMemberships?.length === 1 && leaderOfficeMemberships[0]?.office?.id) || null;
        createCircle({
            variables: {
                name,
                ...(initiativeId && { initiativeId: initiativeId }),
                caseType: !caseType ? null : caseType,
                referralSourceId,
                otherId: !otherId ? null : otherId,
                conductCircleVirtually: +mode === CIRCLE_MODE.mentoring || caseManagementOnly ? false : conductCircleVirtually,
                caseManagementOnly: +mode === CIRCLE_MODE.mentoring ? false : caseManagementOnly,
                officeId: officeId || officeForLeader || leaderHasOneOffice,
                mode: +mode,
                ...(toLeader && { makeCurrentUserLeader: true })
            }
        });
    }

    return <>
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className='mb-0'>Create a New Case</h1>
            <button type="button" onClick={onCancel}><X /></button>
        </div>
        <div className="row">
            <div className="col-lg-8 col-xl-6">
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    {leaderOfficeMemberships?.length > 1 && (
                        <div className="basic-form__group mb-2">
                            <label htmlFor='officeForLeader' className='basic-form__text-label'>Office</label>
                            <select {...register('officeForLeader')} className="basic-form__text-select">
                                <option value=''>Select office</option>
                                {leaderOfficeMemberships.map(om =>
                                    <option key={om.office.id} value={om.office.id}>{om.office.name}</option>
                                )}
                            </select>
                            {errors.officeForLeader && <p className="basic-form__hint">{errors.officeForLeader.message}</p>}
                        </div>
                    )}
                    <div className="basic-form__group mb-0">
                        <label htmlFor='name' className='basic-form__text-label'>Case Ref. or Name</label>
                        <input
                            {...register('name')}
                            placeholder="Name or ref to help you identify this case"
                            className="basic-form__text-box" />
                        {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                    </div>
                    <p className='bold mb-3'>Note: Case Number will be automatically generated</p>

                    <div className="basic-form__group mb-0">
                        <label htmlFor='name' className='basic-form__text-label'>Mode (Restorative, Mediation, Mentoring)</label>
                        <select {...register('mode')} className="basic-form__text-select">
                            <option value=''>Select case mode</option>
                            <option value={CIRCLE_MODE.restorative}>Restorative</option>
                            <option value={CIRCLE_MODE.mediation}>Mediation</option>
                            {currentUser?.organization?.mentoringEnabled &&
                                <option value={CIRCLE_MODE.mentoring}>Mentoring</option>
                            }
                        </select>
                        {errors.mode && <p className="basic-form__hint">{errors.mode.message}</p>}
                    </div>
                    <p className='bold mb-3'>Note: This setting cannot be changed</p>
            
                    {(+watchMode === CIRCLE_MODE.restorative || +watchMode === CIRCLE_MODE.mediation) &&
                        <>
                            <div className="d-flex align-items-center mb-2">

                                <div className="basic-form__group basic-form__group--check mb-1">
                                    <span className="mr-2">Case management only?</span>
                                    <input
                                        type="checkbox"
                                        className="switchToggle"
                                        {...register('caseManagementOnly')}
                                        id='caseManagementOnly'
                                    />
                                    <label className="switchLabel" htmlFor='caseManagementOnly'>&nbsp;</label>
                                </div>
                                <span className='relative'>
                                    <button type='button' className='ml-1' onClick={() => setShowHelp(!showHelp)}><Info /></button>
                                    {showHelp && <div className={styles.help}>
                                        <div className="card">
                                            <div className="card-body">
                                                <p>Turn on when you only wish to use Restorativ for managing the case documentation.</p>
                                                <p>When ON, you’ll be able to enter case members details but they will not be invited or be able to access the case using mobile app.</p>
                                                <button type='button' onClick={() => setShowHelp(false)} className='btn btn-outline'>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </span>
                            </div>
                        </>
                    }
                    {((+watchMode === CIRCLE_MODE.restorative || +watchMode == CIRCLE_MODE.mediation) && !watchManagement) &&
                        <>
                            <div className="basic-form__group basic-form__group--check mb-1">
                                <span className="mr-2">Use virtual chatroom</span>
                                <input
                                    type="checkbox"
                                    className="switchToggle"
                                    {...register('conductCircleVirtually')}
                                    id='conductCircleVirtually'
                                    defaultChecked={false} />
                                <label className="switchLabel" htmlFor='conductCircleVirtually'>&nbsp;</label>
                            </div>
                            <p className='small mb-2'>Even if you decide not to use the virtual chatroom the mobile app will be used to manage communications with participants and upload repair agreements</p>
                        </>
                    }


                    <h2 className='mt-3 mb-1'>Classification</h2>
                    <div className='basic-form__group'>
                        <label htmlFor='initiativeId' className='basic-form__text-label'>Initiative (optional)</label>
                        <select {...register('initiativeId')} className="basic-form__text-select">
                            <option value=''>Please select</option>
                            {currentUser.organization.initiatives.map(el => (
                                <option key={`initiative-${el.id}`} value={el.id}>{el.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className='basic-form__group'>
                        <label htmlFor='referralSourceId' className='basic-form__text-label'>Referral Source* Required</label>
                        <select {...register('referralSourceId')} className="basic-form__text-select">
                            <option value=''>Please select</option>
                            {currentUser.organization.referralSources.map(el => (
                                <option key={`referralSource-${el.id}`} value={el.id}>{el.name}</option>
                            ))}
                        </select>
                        {errors.referralSourceId && <p className='basic-form__hint'>{errors.referralSourceId.message}</p>}
                    </div>
                    {currentUser.organization.others.length > 0 &&
                        <div className='basic-form__group'>
                            <label htmlFor='otherId' className='basic-form__text-label'>Others</label>
                            <select {...register('otherId')} className="basic-form__text-select">
                                <option value=''>Please select </option>
                                {currentUser.organization.others.map(el => (
                                    <option key={`other-${el.id}`} value={el.id}>{el.name}</option>
                                ))}
                            </select>
                        </div>
                    }
                    {+watchMode !== CIRCLE_MODE.mentoring &&
                        <div className='basic-form__group'>
                            <label htmlFor='caseType' className='basic-form__text-label'>Case Type* Required</label>
                            <select {...register('caseType')} className="basic-form__text-select">
                                <option value=''>Please select</option>
                                {currentUser.organization.caseTypes.map(el => (
                                    <option key={`caseType-${el.id}`} value={el.id}>{el.name}</option>
                                ))}
                            </select>
                            {errors.caseType && <p className='basic-form__hint'>{errors.caseType.message}</p>}
                        </div>
                    }

                    {formErrors && <FormErrors errors={formErrors} />}
                    <button type="submit" disabled={loading} className="btn">Create Case</button>
                    <button type="button" onClick={onCancel} className="btn btn-muted ml-2">Cancel</button>
                </form>
            </div>
        </div>
    </>;
}

export default CaseStepCreate;