import moment from "moment";
import { TR_REQUIREMENT_LEVELS } from "./constants";

export const formatDate = dateStr => {
    if (dateStr) {
        const formattedDateStr = dateStr.replace(' ', 'T').replace(' UTC', 'Z');
        return moment(formattedDateStr).format('DD MMM YYYY @ HH.mm');
    }
}

export const capitalize = ([first, ...rest]) => {
    return first.toUpperCase() + rest.join('').toLowerCase();
}

export const getLevelText = level => {
    switch (level) {
        case 0: 
        return 'Trainee'
        case 1:
            return 'Foundation (Lvl 1)';
        case 2:
            return 'Intermediate (Lvl 2)';
        case 3:
            return 'Advanced (Lvl 3)';
        default:
            return '-';
    }
}

export const getLevelExactMatch = level => {
    switch (level) {
        case 0:
            return 'trainee'
        case 1:
            return 'foundation';
        case 2:
            return 'intermediate';
        case 3:
            return 'advanced';
        default:
            return null;
    }
}

export const getLevelIntFromText= level => {
    switch (level) {
        case 'trainee':
            return 1
        case 'foundation':
            return 1;
        case 'intermediate':
            return 2;
        case 'advanced':
            return 3
        default:
            return null;
    }
}

export const getRequirementLevelText = (level, showDash = true) => {
    if (level === TR_REQUIREMENT_LEVELS.desired) {
        return 'D'
    } else if (level === TR_REQUIREMENT_LEVELS.required) {
        return 'R'
    } else {
        return showDash ? '-' : null
    }
}