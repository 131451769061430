import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import FormErrors from "../../../components/FormErrors";
import { ADD_BACKGROUND_CHECK } from "../services/graphql";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { CTC_STATUS } from "../../../utils/constants";
import { VIEW_USER } from "../services/graphql";
import moment from "moment";

const EditCtcForm = ({ member, close, ctcData }) => {

    const [hasCtc, setHasCtc] = useState(ctcData?.ctcStatus >= CTC_STATUS.processing);
    const [formErrors, setFormErrors] = useState([]);

    const { control, register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            status: ctcData?.ctcStatus,
        }
    })

    const [addBackgroundCheck, { loading }] = useMutation(ADD_BACKGROUND_CHECK, {
        update: (_, { data }) => {
            if (data.addBackgroundCheck?.errors?.length > 0) {
                setFormErrors(data.addBackgroundCheck.errors);
            }
            if (data.addBackgroundCheck?.backgroundCheck) {
                close();
            }
        },
        refetchQueries: [{ query: VIEW_USER, variables: { id: member.id } }]
    });

    const watchStatus = watch('status');

    const onSubmit = data => {
        const { date, status, reference } = data;
        addBackgroundCheck({
            variables: {
                userProfileId: member.userProfile.id,
                ctcStatus: hasCtc ? +status : null,
                ctcCheckDate: hasCtc ? date : null,
                ctcReferenceNumber: hasCtc ? reference : null,
            }
        })
    }

    return (
        <div className="flex-grow-1">
            <div className="d-flex justify-content-between">
                <p>CTC Checked?</p>
                <div className="basic-form__group basic-form__group--check">
                    <input
                        type="checkbox"
                        className="switchToggle switchToggleWhite"
                        id='ctc'
                        onChange={(e) => setHasCtc(e.target.checked)}
                        defaultChecked={hasCtc} />
                    <label className="switchLabel switchLabelWhite" htmlFor='ctc'>&nbsp;</label>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="basic-form-white">
                {hasCtc &&
                    <>
                        <label>Status</label>
                        <select
                            defaultValue={+ctcData?.ctcStatus}
                            {...register('status', { required: 'Please select a status' })}
                            className="basic-form__text-select"
                        >
                            <option value=''>Please select</option>
                            <option value={+CTC_STATUS.processing}>Processing</option>
                            <option value={+CTC_STATUS.passed}>Passed</option>
                            <option value={+CTC_STATUS.denied}>Denied</option>
                        </select>
                        {errors.status && <p>{errors.status.message}</p>}
                        {+watchStatus === CTC_STATUS.passed &&
                            <>
                                <div className="mt-1">
                                    <label htmlFor="date">Date Awared</label>
                                    <div className="width-full fullPicker dark">
                                        <Controller
                                            control={control}
                                            name="date"
                                            defaultValue={ctcData?.ctcCheckDate ? moment(ctcData.ctcCheckDate).toDate() : ''}
                                            render={({ field }) => (
                                                <ReactDatePicker
                                                    {...field}
                                                    dateFormat={'dd/MM/yyyy'}
                                                    autoComplete='off'
                                                    className="basic-form__text-box d-block width-full"
                                                    placeholderText="Select date"
                                                    selected={field.value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                {errors.date && <p>{errors.date.message}</p>}

                                <label htmlFor="date mt-2">Reference Number</label>
                                <input defaultValue={ctcData?.ctcReferenceNumber} className="basic-form__text-box" type="text" {...register('reference', { required: 'Please add Reference number' })}></input>
                                {errors.reference && <p>{errors.reference.message}</p>}
                            </>
                        }
                    </>
                }
                {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                <button type="submit" disabled={loading} className="btn mr-2 mt-2">Save</button>
                <button type="button" onClick={close} className="btn btn-outline mt-2">Cancel</button>
            </form>
        </div>
    )
}

export default EditCtcForm;