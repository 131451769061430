import React, { useContext } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import ls from 'local-storage';
import Reports from '../Reports';
import OfficeReportsContent from '../Reports/OfficeReportsContent';
import MyCases from '../Cases/scenes/MyCases';
import PractitionerCompliance from '../PractitionerCompliance';

const Dashboard = () => {

  const { currentUser } = useContext(AuthContext);
  const officeContext = ls('office_context') && JSON.parse(ls('office_context'));


  const officeMemberships = currentUser?.officeMemberships || [];

  const officeAdminMemberships = officeMemberships.filter(m => m.isAdmin);
  const officeSupervisorMemberships = officeMemberships.filter(m => m.isSupervisor);
  const officeCLMemberships = officeMemberships.filter(m => m.isCircleLeader);

  const getOfficeContext = (memberships) => {
    return officeContext && memberships.some(om => om.office.id === officeContext.id)
      ? officeContext
      : memberships[0]?.office;
  };

  const getAdminOfficeContext = getOfficeContext(officeAdminMemberships);
  const getSupervisorOfficeContext = getOfficeContext(officeSupervisorMemberships)


  const renderDashContent = () => {
    if (currentUser?.isOrganizationAdmin) {
      return <Reports />
    }

    if (getSupervisorOfficeContext && officeAdminMemberships.length === 0 && officeSupervisorMemberships.length > 0) {
      return <PractitionerCompliance officeId={getSupervisorOfficeContext.id} />
    }

    if (getAdminOfficeContext && officeAdminMemberships.length > 0) {
      return <OfficeReportsContent officeId={getAdminOfficeContext.id} />
    }

    if (officeCLMemberships && !getAdminOfficeContext) {
      return <MyCases />
    }
    return null
  }

  return (
    <>
      {renderDashContent()}
    </>
  )
}


export default Dashboard;
