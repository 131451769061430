
import React, { useState, useEffect, useContext } from "react";
import { AlertCircle, Plus } from "react-feather";
import NewMember from "./components/NewMember";
import { USER_ROLES } from "../../utils/constants";
import styles from './Team.module.scss';
import { AuthContext } from "../../services/auth/AuthProvider";
import ViewMember from "./components/ViewMember";

const TeamContent = ({ users, organization }) => {

    const { currentUser } = useContext(AuthContext);

    const [term, setTerm] = useState('');
    const [showAdd, setShowAdd] = useState(false);
    const [showMember, setShowMember] = useState(null);

    const [filters, setFilters] = useState({
        byOffice: [...organization.offices.map(o => o.id)],
        byRole: [...USER_ROLES.map(r => r.value)]
    });
    const [filteredUsers, setFilteredUsers] = useState(users);

    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    const handleSearch = ({ target: { value } }) => setTerm(value)

    const handleOfficeChange = ({ target: { value } }) => {
        setFilters(prevFilters => {
            const newByOffice = prevFilters.byOffice.includes(value) ?
                prevFilters.byOffice.filter(el => el !== value) : [...prevFilters.byOffice, value]
            return { ...prevFilters, byOffice: newByOffice }
        })
    }

    const handleRoleChange = ({ target: { value } }) => {
        setFilters(prevFilters => {
            const newByRole = prevFilters.byRole.includes(+value) ?
                prevFilters.byRole.filter(el => el !== +value) : [...prevFilters.byRole, +value]
            return { ...prevFilters, byRole: newByRole }
        })
    }


    const applyFilters = () => {
        const filteredByOffice = filters.byOffice.length === 0 ? users : users.filter(user => {
            return user.offices.some(office => filters.byOffice.includes(office.id))
        }
        );
        const filteredByOfficeAndRole = filters.byRole.length === 0 ? filteredByOffice :
            filteredByOffice.filter(user => {
                let includeUser = false;
                if (filters.byRole.includes(1) && user.officeMemberships.find(m => m.isCircleLeader)) includeUser = true;
                if (filters.byRole.includes(2) && !user.isOrganizationAdmin && user.officeMemberships.find(m => m.isAdmin)) includeUser = true;
                if (filters.byRole.includes(3) && user.isOrganizationAdmin) includeUser = true;
                if (filters.byRole.includes(4) && user.officeMemberships.find(m => m.isSupervisor)) includeUser = true;
                return includeUser;

            });
        setFilteredUsers(filteredByOfficeAndRole);
    }

    const itemsToShow = term === '' ? filteredUsers : filteredUsers.filter(u =>
        (`${u.firstName} ${u.lastName}`).toLowerCase().includes(term.toLowerCase())
    );


    if (showMember) {
        return (
            <ViewMember
                currentUser={currentUser}
                organization={organization}
                close={() => setShowMember(null)}
                user={showMember}
            />
        )
    }
    if (showAdd) {
        return <NewMember organization={organization} close={() => setShowAdd(false)} />
    }
    return (
        <>
            <div className="d-flex mb-3 align-items-center">
                <h1>Manage team</h1>
                <div className="ml-auto d-flex  align-items-center">
                    <input placeholder="Search" type="search" value={term} onChange={handleSearch} className="basic-form__text-box mb-0" />
                    <button type="button" onClick={() => setShowAdd(true)} className="btn btn-primary d-flex px-1 ml-1"><Plus /></button>
                </div>
            </div>
            <div className={styles.team}>
                <div className={`${'card card-blue'} ${styles.teamFilters}`}>
                    <div className="card-body">
                        <p className="uppercase text-muted-2 bold mb-1">Offices</p>
                        {organization.offices.map(office =>
                            <p className="mb-1 d-flex align-items-center" key={`useroffice-${office.id}`}>
                                <input onChange={handleOfficeChange} type="checkbox" value={office.id} defaultChecked={filters.byOffice.includes(office.id)} />
                                <span className="ml-1">{office.name}</span>
                            </p>
                        )}

                        <p className="uppercase text-muted-2 bold mb-1 mt-2">Roles</p>
                        {USER_ROLES.map(role =>
                            <p className="mb-1 d-flex align-items-center" key={`userole-${role.value}`}>
                                <input onChange={handleRoleChange} type="checkbox" value={+role.value} defaultChecked={filters.byRole.includes(+role.value)} />
                                <span className="ml-1">{role.name}</span>
                            </p>
                        )}

                        <button disabled={filters.byOffice.length === 0} type="button" className="btn btn-outline mt-2" onClick={applyFilters}>Apply</button>
                    </div>
                </div>

                <ul className="list-group">
                    {[...itemsToShow].sort((a, b) => (a.lastName || '').localeCompare(b.lastName || '')).map(user =>
                        <li className="list-item list-item--separator" key={user.id}>
                            <button className=" d-flex justify-content-between width-full" onClick={() => setShowMember(user)}>
                                <div className="d-flex align-items-center"> {!user.active && <AlertCircle className="mr-1" />} {user.firstName} {user.lastName}</div>
                                <span className="d-flex align-items-center">
                                    {user.officeMemberships.find(m => m.isSupervisor) && <span className="label label-yellow mr-1">S</span>}
                                    {user.officeMemberships.find(m => m.isCircleLeader) && <span className="label label-green mr-1">CL</span>}
                                    {user.isOrganizationAdmin && <span className="label label-blue">Org. A</span>}
                                    {!user.isOrganizationAdmin && user.officeMemberships.find(om => om.isAdmin) && <span className="label label-primary ml-1">{user.officeMemberships.filter(om => om.isAdmin).map(om => om.office.name).join(', ')}</span>}
                                </span>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </>
    )
}

export default TeamContent;