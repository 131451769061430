import React, { useState } from "react";
import ReferralItem from "./components/ReferralItem";
import { Info, Plus, XCircle } from "react-feather";
import ViewReferral from "./ViewReferral";
import { REFERRAL_STATUS } from "../../utils/constants";
import { useFilteredList } from "../../hooks/useFilteredList";
import SideModal from "../../components/SideModal";
import CreateReferralPdf from "./components/CreateReferralPdf";

const ReferralsContent = ({ officeId, referrals, organization }) => {

    const [viewRef, setViewRef] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [showAlert, setShowAlert] = useState(null);

    const [filter, setFilter] = useState(officeId ? REFERRAL_STATUS.has_office : REFERRAL_STATUS.new);

    const { term, handleTermChange, itemsToShow, filteringReady } = useFilteredList(referrals || [], 100, 'youngPersonFirstName');

    const sortedItems = [...referrals].filter(el => el.status === +filter);
    const visibleItems = term === '' ? sortedItems.sort((a, b) => (b.referralDate || '').localeCompare((a.referralDate || ''))) : itemsToShow;

    if (viewRef) {
        return <ViewReferral officeId={officeId} organization={organization} referralItem={viewRef} close={() => setViewRef(null)} />
    }

    return (
        <>
            {showAlert &&
                <div className="bg-blue p-2 radius-2 text-white d-flex justify-content-between mb-2 align-items-center">
                    <p className="d-flex align-items-center"><Info className="mr-1" />{showAlert}</p>
                    <button onClick={() => setShowAlert(null)}> <XCircle /></button>
                </div>
            }
            <div className="mt-2">
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <p>Status:</p>
                        <select
                            value={filter}
                            onChange={(e) => setFilter(+e.target.value)}
                            className="basic-form__text-select"
                            style={{ minWidth: '200px' }}
                        >
                            {officeId ?
                                <>
                                    <option value={REFERRAL_STATUS.has_office}>New</option>
                                    <option value={REFERRAL_STATUS.has_case}>Assigned</option>
                                </> : <>
                                    <option value={REFERRAL_STATUS.new}>New</option>
                                    <option value={REFERRAL_STATUS.has_office}>Assigned</option>
                                    <option value={REFERRAL_STATUS.has_case}>Case created</option>
                                </>
                            }
                            <option value={REFERRAL_STATUS.rejected}>Rejected</option>
                        </select>
                    </div>
                    <div className="ml-auto d-flex pt-3  align-items-center">
                        <input
                            size={30}
                            type="text"
                            value={term}
                            placeholder="Search by Young Person Name"
                            className="basic-form__text-box mb09"
                            onChange={handleTermChange}
                            results={+true}
                        />

                        <button
                            type="button" 
                            onClick={() => setShowAdd(true)} 
                            className={`btn d-flex px-1 ml-1 ${officeId ? 'btn-primary': 'btn-blue'}`}
                        ><Plus /></button>

                    </div>
                </div>
                <ul className="list-group">
                    {visibleItems.length === 0 && filter === REFERRAL_STATUS.new && term === '' ?
                        <div className="row">
                            <div className="col-md-6">
                                <h3>No new referrals</h3>
                                <p>New referrals will be shown here as they come in from TypeForm.</p>
                                <p>To view referrals you have already assigned or rejected, please use the status filter or search feature.</p>
                            </div>
                        </div>
                        :
                        visibleItems.length === 0 && filteringReady ? <p>No referrals {term !== '' && ' match'}</p> :
                            <> {visibleItems.map(referral => <ReferralItem setViewRef={setViewRef} key={referral.id} referral={referral} />)}</>}
                </ul>

            </div>
 

            {showAdd && (
                <SideModal closeModal={() => setShowAdd(false)}>
                    <CreateReferralPdf
                        setShowAlert={setShowAlert}
                        close={() => setShowAdd(false)}
                        organization={organization}
                        officeId={officeId}
                    />
                </SideModal>
            )}
        </>
    )
}

export default ReferralsContent;