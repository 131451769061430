import React from "react";
import { Link } from "@reach/router";
import LevelBadge from "../Team/components/LevelBadge";
import { ArrowLeft, CheckCircle, Edit, XCircle, Clock, AlertCircle } from "react-feather";
import { CTC_STATUS, USER_TR_STATUS } from "../../utils/constants";
import { navigate } from "@reach/router";
import moment from "moment";
import styles from './TrainingRec.module.scss';
import { useParams } from "@reach/router";
import { TR_REQUIREMENT_LEVELS } from "./TrainingRecLevel/RequirementItem";

const TrainingHeader = ({ userData, currentUser, fromView, setShowEdit }) => {

    const params = useParams();

    const getNavUrlFor = (section) => {
        const officePart = params.officeId ? `/offices/${params.officeId}` : '';
        return `${officePart}/practitionerCompliance/${fromView}/${section}`;
    }

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: `${styles.navLink} ${styles.navLinkActive}` } : { className: styles.navLink }
    }

    const renderCtc = (userProfile) => {
        const ctcData = userProfile?.backgroundChecks?.find(el => el.ctcStatus >= CTC_STATUS.processing);
        switch (ctcData?.ctcStatus) {
            case CTC_STATUS.processing:
                return (
                    <div className={`${styles.itemLevel} l-amber`}>
                        <p><Clock /></p>
                        <p className="small">In progress</p>
                    </div>
                );
            case CTC_STATUS.passed:
                return (
                    <div className={`${styles.itemLevel} l-green`}>
                        <p><CheckCircle /></p>
                        {ctcData.ctcCheckDate && <p className="small">{moment(ctcData.ctcCheckDate).format('DD/MM/YYYY')}</p>}
                    </div>
                );
            case CTC_STATUS.denied:
                return (
                    <div className={`${styles.itemLevel} l-red`}>
                        <p><XCircle /></p>
                        <p className="small">Denied</p>
                    </div>
                );
            default:
                return (
                    <div className={styles.itemLevel}>
                        <p><XCircle /></p>
                        <p className="small">No check</p>
                    </div>
                )
        }
    }

    const getCpdStyles = (practitionerCompliance) => {
        if (practitionerCompliance.cpdHoursTotal === 0) {
            return 'l-red'
        } else if (practitionerCompliance.cpdHoursTotal > 0 && practitionerCompliance.cpdHoursTotal < practitionerCompliance.cpdHoursTarget) {
            return 'l-amber'
        } else if (practitionerCompliance.cpdHoursTotal >= practitionerCompliance.cpdHoursTarget) {
            return 'l-green'
        }
    }

    const getLevelFieldText = () => {
        switch (userData.practitionerLevel) {
            case 1:
            case 0:
                return 'foundation'
            case 2:
                return 'intermediate'
            case 3:
                return 'advanced'
            default:
                return '---';
        }
    }

    const allRequirements = userData.organization?.trainingRequirements || [];

    const requiredCourses = allRequirements.filter(el => el[getLevelFieldText()] === TR_REQUIREMENT_LEVELS.required).map(el => el.id);
    const desiredCourses = allRequirements.filter(el => el[getLevelFieldText()] === TR_REQUIREMENT_LEVELS.desired).map(el => el.id)

    const userRequiredDone = userData.userTrainingRequirements.filter(el => el.currentMatch && el.status !== USER_TR_STATUS.declined && el.trainingRequirement && requiredCourses.includes(el.trainingRequirement?.id)).length;
    const userDesiredDone = userData.userTrainingRequirements.filter(el => el.currentMatch && el.status !== USER_TR_STATUS.declined && el.trainingRequirement && desiredCourses.includes(el.trainingRequirement?.id)).length;

    const getCoursesBg = () => {
        if (userRequiredDone === 0 && requiredCourses.length > 0) {
            return 'l-red'
        } else if (userRequiredDone < requiredCourses.length) {
            return 'l-amber'
        } else if (userRequiredDone >= requiredCourses.length) {
            return 'l-green'
        }
    }

    return (
        <>
            {fromView && (
                <>
                    <button onClick={() => navigate(-1)} className="mb-2 d-flex align-items-center">
                        <ArrowLeft className="mr-1 text-primary" />Back
                    </button>
                    <div className="mb-2 d-flex align-items-center">
                        <h1 className="mb-0 mr-2">{userData.firstName} {userData.lastName}</h1>
                        {currentUser.isOrganizationAdmin &&
                            <button className="d-flex align-items-center" onClick={() => setShowEdit(true)}><Edit className='mr-1 text-primary' /> Manage</button>
                        }
                    </div>
                </>
            )}
            <div className={styles.header}>
                <div>
                    <p>Current level</p>
                    {userData.practitionerLevel === null ?
                        <div className={`${styles.noPractitionerLevel} l-red`}>
                            <AlertCircle />
                            <p className="mt-1">No Practitioner Level Set</p>
                        </div> :
                        <LevelBadge level={userData.practitionerLevel} date={userData.practitionerLevelDate} />
                    }
                </div>
                <ul className={styles.nav}>
                    <li>
                        <Link getProps={isActive} to={`${fromView ? `${getNavUrlFor('cpd')}` : '/myTraining/cpd'}`}>
                            <p className="bold">CPD</p>
                            {userData.practitionerLevel === null ?
                                <div className={`${styles.itemLevel}`}>
                                    <p className="bold lead">-/-</p>
                                    <p className="small">-</p>
                                </div>
                                :
                                <div className={`${styles.itemLevel} ${getCpdStyles(userData.practitionerCompliance)}`}>
                                    <p className="bold lead">{userData.practitionerCompliance?.cpdHoursTotal}/{userData.practitionerCompliance?.cpdHoursTarget}</p>
                                    <p className="small">Entries: {userData.practitionerCompliance?.cpdLogsCount}</p>
                                </div>
                            }
                        </Link>
                    </li>


                    {(requiredCourses?.length > 0 || desiredCourses?.length > 0) &&
                        <li>
                            <Link getProps={isActive} to={`${fromView ? `${getNavUrlFor('trainingContent')}` : '/myTraining/trainingContent'}`}>
                                {/* <p className="bold">Training & RJC Level</p> */}
                                <p className="bold">Training</p>

                                {userData.practitionerLevel === null ?
                                    <div className={styles.itemsWrapper}>
                                        <div className={`${styles.itemLevel}`}>
                                            <p className="bold lead">-/-</p>
                                            <p className="small">-</p>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.itemsWrapper}>
                                        <div className={`${styles.itemLevel} ${getCoursesBg()}`}>
                                            <p className="bold lead">{userRequiredDone}/{requiredCourses.length}</p>
                                            <p className="small">{desiredCourses.length > 0 ? <>{userDesiredDone}/{desiredCourses.length} desired</> : '-'} </p>
                                        </div>
                                        {/* 
                                <div className={`${styles.itemLevel} l-green`}>
                                    <p className="bold lead">F</p>
                                    <p className="small">0/1 desired </p>
                                </div> */}
                                    </div>
                                }

                            </Link>
                        </li>
                    }
                    <li>
                        <div className={`${styles.navLink} ${styles.navBlock}`}>
                            <p className="bold">CTC</p>
                            {renderCtc(userData.userProfile)}
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default TrainingHeader;