
export const circleMembership = {
    leader: 1,
    responsibleParty: 2,
    affectedParty: 3,
    communityMember: 4,
    supportMember: 5,
    secondLeader: 6,
    participant: 7
}

export const CIRCLE_MODE = {
    restorative: 1,
    mediation: 2,
    mentoring: 3
}

export const CIRCLE_STATUS = {
    draft: -1,
    started: 0,
    opening: 1,
    agreement_repair: 2,
    closing: 3, // NOT used anymore
    closed_pending_assessment: 4,
    closed_outcomes_met: 5,
    closed_no_outcomes_met: 99 // outcomes not met
}

export const CTC_STATUS = {
    processing: 1,
    passed: 2,
    denied: 99 
}

export const AGREEMENT_STATUS = {
    new: 0,
    accepted: 1,
    rejected: 2,
}

export const SESSION_STATUS = {
    new: 1,
    missed: 2,
    completed: 3
}

export const REFERRAL_STATUS = {
    new: 1,
    has_office: 2,
    has_case: 3,
    rejected: 99
}

export const DEBRIEF_STATUS = {
    new: 1,
    approved: 2,
    rejected: 99
}

export const CIRCLE_PHASE = {
    opening: 1,
    closing: 3,
}
export const getRoleLabel = role => {
    switch (role) {
        case circleMembership.leader:
            return 'Leader';
        case circleMembership.responsibleParty:
            return 'Responsible party';
        case circleMembership.affectedParty:
            return "Affected party";
        case circleMembership.communityMember:
            return "Community Member";
        case circleMembership.supportMember:
            return "Support Member";
        case circleMembership.secondLeader:
            return 'Second leader'
        default:
            return '--'
    }
};

export const getShortRoleLabel = role => {
    switch (role) {
        case circleMembership.leader:
            return 'CL';
        case circleMembership.responsibleParty:
            return 'RP';
        case circleMembership.affectedParty:
            return "AP";
        case circleMembership.communityMember:
            return "CM";
        case circleMembership.supportMember:
            return "SM";
        case circleMembership.secondLeader:
            return 'SL'
        case circleMembership.participant:
            return null
        default:
            return '--'
    }
};


export const USER_ROLES = [
    { value: 1, name: 'Circle Leaders' },
    { value: 2, name: 'Office Admins' },
    { value: 3, name: 'Org Admins' },
    { value: 4, name: 'Supervisors' },

]

export const DOCUMENT_TYPES = {
    training: 1,
    information: 2,
    template: 3,
    administration: 4
}

export const LOGIN_SCOPES = {
    superAdmin: 1,
    orgAdmin: 2
}


export const TR_REQUIREMENT_LEVELS = {
    desired: 1,
    required: 2
}
export const USER_TR_STATUS = {
    declined: -1,
    approved: 1
}

export const MENTORING_CATEGORY_TYPES = {
    sessionCateg: 'MentoringSessionCategory',
    sessionTheme: 'MentoringSessionPlannedTheme',
    cancellationReason: 'MentoringCancellationReason',
    sessionBreaks: 'MentoringSessionBreaks'
}

export const getDocumentLabel = type => {
    switch (type) {
        case DOCUMENT_TYPES.training:
            return 'Training';
        case DOCUMENT_TYPES.information:
            return 'Information';
        case DOCUMENT_TYPES.template:
            return 'Template';
        case DOCUMENT_TYPES.administration:
            return 'Administration';
        default:
            return '--'
    }
};

export const referralQuery = `
id
status
createdAt
referralDate
addedBy {
    id
    firstName
    lastName
}
fileUrl
circle {
    id
}
office {
    id
    name
}
referrerEmail
referrerFirstName
referrerLastName
referrerCompany
referrerDepartment
referrerPhoneNumber
youngPersonFirstName
youngPersonLastName
rejectedReason
ypTakingMedication
youngPersonEthnicity
typeformData {
    agenciesContacted
    familyStructure
    mentorPreferred
    parent1Address
    parent1Address2
    parent1City
    parent1Country
    parent1County
    parent1Email
    parent1FirstLanguage
    parent1Firstname
    parent1Lastname
    parent1NeedsInterpreter
    parent1PhoneNumber
    parent1Zipcode
    parent2Address
    parent2Address2 
    parent2City 
    parent2Country 
    parent2County 
    parent2Email 
    parent2FirstLanguage 
    parent2Firstname 
    parent2Lastname 
    parent2NeedsInterpreter
    parent2Phone 
    parent2Zipcode 
    phoneCallRequired
    referralReason 
    referrerCompany 
    referrerDepartment 
    riskFromOthers
    riskToThemselves
    significantHealthConditions 
    specialConsideration 
    youngPersonAddress 
    youngPersonCity 
    youngPersonCountry 
    youngPersonDateOfBirth
    youngPersonFirstName
    youngPersonLastName
    youngPersonFluentInEnglish
    youngPersonGender
    youngPersonHasAgreedTheReferral
    youngPersonHomeLanguage
    youngPersonLanguagesSpoken
    youngPersonZipcode
    ypAwareOfProcessOfDisclosure
    ypCurrentlyInvolvedWithAgencies
    ypHasADiagnostic
    ypHasEmotionalOrBehavioralIssues
    ypHasLearningDifficulties
    ypHasMentalHealthIssues
    ypInvolvedWithAgenciesInThePast
    ypWasSubjectOfCpPlan
}
`;

export const organizationQuery = `
id
name
logo
createdAt
mentoringEnabled
administrativeRegion {
    id
    name
    countryCode
    trainingRecordsEnabled
}
offices {
    id
    name
}
initiatives {
    id
    name
    type
}
caseTypes {
    id
    name
    type
}
referralSources {
    id
    name
    type
}
others {
    id
    name
    type
}
mentoringSessionCategories {
    id
    name
    type
}
mentoringSessionPlannedThemes {
    id
    name
    type
}
mentoringCancellationReasons {
    id
    name
    type
}
mentoringLocations {
    id
    name
    address1
    address2
    address3
    city
    country
    zipCode
}
sessionThreshold
referrals {
    id
    status
    circle {
        id
    }
    office {
        id
    }
}
`;

export const currentUserQuery = `
id
firstName
lastName
email
isOrganizationAdmin
photoUrl
officeMemberships {
    id
    isAdmin
    isCircleLeader
    isSupervisor
    office {
        id
        name
    }
}
practitionerLevel
practitionerLevelDate
organization {
    ${organizationQuery}
}
`;