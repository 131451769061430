import React, { useContext } from "react";
import { AuthContext } from "../../../services/auth/AuthProvider";
import TrainingRecLevelContent from "./TrainingRecLevelContent";

const TrainingRecLevel = ({ userId }) => {

    const { currentUser } = useContext(AuthContext);

    return <TrainingRecLevelContent fromView={userId} userId={userId ? userId : currentUser.id} />

}

export default TrainingRecLevel;