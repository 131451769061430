import React, { useState } from "react";
import SideModal from '../../../components/SideModal';
import { AlertTriangle, CheckCircle, Edit, Slash } from "react-feather";
import styles from '../TrainingRec.module.scss';
import AddRequirement from "./AddRequirement";
import { getRequirementLevelText } from "../../../utils/functions";
import EditMatch from "./EditMatch";
import moment from "moment";

export const TR_REQUIREMENT_LEVELS = {
    desired: 1,
    required: 2
}
const RequirementItem = ({ item, userId, practitionerLevel, userQualifications, fromView }) => {

    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const combinedUserReq = [...item.userTrainingRequirements, ...item.declinedUserTrainingRequirements];

    const currentMatch = item.userTrainingRequirements.find(el => el.currentMatch);
    const showDeclinedIcon = !currentMatch && item.declinedUserTrainingRequirements?.length > 0;
    const declinedMatch = item.declinedUserTrainingRequirements[0];

    const getLevelFieldText = () => {
        switch (practitionerLevel) {
            case 1:
            case 0:
                return 'foundation'
            case 2:
                return 'intermediate'
            case 3:
                return 'advanced'
            default:
                return '---';
        }
    }

    const getBg = () => {
        const targetLevel = getLevelFieldText();
        const targetValue = item[targetLevel];
        // if (item.userTrainingRequirements.length > 0) {
        if (item.userTrainingRequirements.find(el => el.currentMatch)) {
            return 'l-green'
        }
        if (!item.userTrainingRequirements.find(el => el.currentMatch) && item.declinedUserTrainingRequirements.length > 0) {
            return 'l-red';
        }

        switch (targetValue) {
            case TR_REQUIREMENT_LEVELS.required:
                return 'l-red';
            case TR_REQUIREMENT_LEVELS.desired:
                return 'l-blue';
            case null:
                return 'l-grey'
            default:
                return 'l-grey'
        }
    }

    const renderTrMeta = () => {
        if (currentMatch) {
            const dateAttended = currentMatch.trainingQualification ? currentMatch.trainingQualification.dateAttended : currentMatch.dateAttended;
            return <>{dateAttended && moment(new Date(dateAttended)).format('DD MMM YYYY')} {currentMatch.trainingQualification?.name}</>
        }
        if (!currentMatch && showDeclinedIcon && declinedMatch) {
            return <>{declinedMatch.declinedBy.firstName} {declinedMatch.declinedBy.lastName} declined your match</>
        }
        return <>--</>
    }

    return (
        <>
            <div className={styles.reqItem}>
                <span className={`${styles.reqItemLabel} ${getBg()}`} >
                    {currentMatch ? <CheckCircle /> : showDeclinedIcon ? <AlertTriangle /> : <Slash />}
                </span>
                <div className={styles.reqItemName}>
                    <h4 className="bold mb-0">{item.courseTitle}</h4>
                    <div>{renderTrMeta()}</div>
                </div>
                <div className={styles.reqItemSide}>
                    <ul className={styles.reqItemLevels}>
                        <li className={`${styles.reqItemLevelsEl} ${item.foundation !== null ? styles.reqItemValue : ''}`}>
                            {getRequirementLevelText(item.foundation, false)}
                        </li>
                        <li className={`${styles.reqItemLevelsEl} ${item.intermediate !== null ? styles.reqItemValue : ''}`}>
                            {getRequirementLevelText(item.intermediate, false)}
                        </li>
                        <li className={`${styles.reqItemLevelsEl} ${item.advanced !== null ? styles.reqItemValue : ''}`}>
                            {getRequirementLevelText(item.advanced, false)}
                        </li>
                    </ul>
                    <div className={styles.reqItemEdit}>
                        {fromView && combinedUserReq.length > 0 && <button onClick={() =>
                            setShowEdit(true)
                        }><Edit /></button>}

                        {!fromView &&
                            <button onClick={() =>
                                combinedUserReq.length === 0
                                    ? setShowAdd(true) : setShowEdit(true)
                            }><Edit /></button>
                        }
                    </div>
                </div>
            </div>
            {showAdd && (
                <SideModal closeModal={() => setShowAdd(false)}>
                    <AddRequirement userQualifications={userQualifications} userId={userId} item={item} close={() => setShowAdd(false)} />
                </SideModal>
            )}

            {showEdit && (
                <SideModal closeModal={() => setShowEdit(false)}>
                    <EditMatch fromView={fromView} userQualifications={userQualifications} userId={userId} item={item} close={() => setShowEdit(false)} />
                </SideModal>
            )}
        </>
    )
}

export default RequirementItem;