import React from "react";
import { getLevelText } from "../../../utils/functions";
import moment from "moment";
import styles from '../Team.module.scss';

const LevelBadge = ({ level, date, className = '' }) => {
    
    return (
        <div className={`${styles.levelBadge} ${className}`}>
            <h3 className="mb-0 bold">{getLevelText(level)}</h3>
            <p className="small">Awarded: {moment(date).format('DD MMM YYYY')}</p>
        </div>
    )
}

export default LevelBadge;