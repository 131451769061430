import React, { useState } from "react";
import AddRequirement from "./AddRequirement";
import { AlertTriangle, PlusCircle, Star } from "react-feather";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import MatchItem from "./MatchItem";

const EditMatch = ({ userId, item, close, userQualifications, fromView }) => {

    const [showAdd, setShowAdd] = useState(false);
 
    const currentMatch = item.userTrainingRequirements.find(el => el.currentMatch);

    const prevCurrentQualifiers = item.userTrainingRequirements.filter(el => !el.currentMatch);
    const prevDeclinedQualifiers = item.declinedUserTrainingRequirements;
    const prevQualifiers = [...prevCurrentQualifiers, ...prevDeclinedQualifiers].sort((a, b) => (b.dateAttended || '').localeCompare(a.dateAttended));

    return (
        <>
            {showAdd ? <AddRequirement userQualifications={userQualifications} userId={userId} item={item} close={() => setShowAdd(false)} /> :
                <>
                    <h1 className="mb-1">{item.courseTitle}</h1>
                    {item.externalRequirement && <p className="mb-1">Requirement: {item.requiredLevel}</p>}
                    {currentMatch ?
                        <>
                            <p className="d-flex align-items-center mb-2"><Star className="mr-1" /> Current qualifier</p>
                            <MatchItem match={currentMatch} currentMatch={currentMatch} close={close} userId={userId} fromView={fromView} />
                        </>
                        : <p className="d-flex align-items-center mb-2"><AlertTriangle className="mr-1" /> No current match</p>}

                    {!fromView &&
                        <div className="mb-3">
                            <button className="btn d-flex align-items-center" onClick={() => setShowAdd(true)}><PlusCircle className="mr-1 " size={18} />Attended Refresher or New Course</button>
                        </div>
                    }

                    <p className="mb-1">Previous Qualifiers</p>
                    {prevQualifiers.length > 0 ? (
                        <div>
                            {prevQualifiers.map(el => <MatchItem key={el.id} currentMatch={currentMatch} close={close} match={el} userId={userId} fromView={fromView} />)}
                        </div>
                    ) : <p>No Previous Qualifiers</p>
                    }
                </>
            }
        </>
    )
}

export default EditMatch;