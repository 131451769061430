import { gql } from "@apollo/client";

export const PRACTITIONER_COMPLIANCE = gql`
query practitionerComplianceLeaders($organizationId: ID, $officeId: ID) {
    practitionerComplianceLeaders(organizationId: $organizationId, officeId: $officeId) {
        id
        user {
            id
            firstName
            lastName
        }
        practitionerLevel
        cpdHoursTarget
        cpdHoursTotal
        cpdLogsCount
        ctcCheckDate
        ctcStatus
        advancedCourses
        foundationCourses
        intermediateCourses
        advancedDesired
        foundationDesired
        intermediateDesired
    }
}
`;