import React, { useState, useContext } from "react";
import SideModal from "../../../../components/SideModal";
import RequirementForm from "./RequirementForm";
import { Plus, Edit } from "react-feather";
import Loader from '../../../../components/Loader'
import { useQuery } from "@apollo/client";
import { GET_TRAINING_REQUIREMENTS } from "../../services/graphql";
import { AuthContext } from "../../../../services/auth/AuthProvider";
import styles from './TrainingSettings.module.scss';
import { TR_REQUIREMENT_LEVELS } from "../../../../utils/constants";
import { getRequirementLevelText } from "../../../../utils/functions";

const TrainingSettings = () => {

    const { currentUser } = useContext(AuthContext);


    const [showEdit, setShowEdit] = useState(null);
    const [showAdd, setShowAdd] = useState(false);

    const { data, loading, error } = useQuery(GET_TRAINING_REQUIREMENTS, {
        variables: {
            organizationId: currentUser?.organization?.id
        }
    })

    const getLevelCls = level => {
        if (level === TR_REQUIREMENT_LEVELS.desired) {
            return 'l-blue'
        } else if (level === TR_REQUIREMENT_LEVELS.required) {
            return 'l-green'
        } else {
            return 'l-grey'
        }
    }


    if (error) return <p>{error.message}</p>

    if (loading) return <Loader />

    if (data?.organization) {

        const trainingRequirements = data.organization.trainingRequirements || [];
        return (
            <>
                <div className="d-flex mb-2 justify-content-end">
                    <button className="btn btn-blue d-flex px-1" onClick={() => setShowAdd(true)}>
                        <Plus />
                    </button>
                </div>


                {
                    trainingRequirements.length > 0 ?
                        <>
                            <ul className={styles.header}>
                                <li>F</li>
                                <li>I</li>
                                <li>A</li>
                            </ul>
                            <ul className="list-group">
                                {trainingRequirements.map(item => (
                                    <li key={item.id} className={`list-item list-item--separator-dark p-0 mb-1 ${styles.course}`}>
                                        <p className="d-flex align-items-center pl-2">
                                            {item.courseTitle}
                                        </p>
                                        <div className="ml-auto d-flex align-items-center">
                                            <ul className={styles.levels}>
                                                <li className={`${styles.levelsItem} ${getLevelCls(item.foundation)}`}>{getRequirementLevelText(item.foundation)}</li>
                                                <li className={`${styles.levelsItem} ${getLevelCls(item.intermediate)}`}>{getRequirementLevelText(item.intermediate)}</li>
                                                <li className={`${styles.levelsItem} ${getLevelCls(item.advanced)}`}>{getRequirementLevelText(item.advanced)}</li>
                                            </ul>
                                            <button type="button" className='d-flex align-items-center mr-2' onClick={() => setShowEdit(item)}><Edit /></button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </> : <p>No training requirements</p>
                }

                {showEdit && (
                    <SideModal closeModal={() => setShowEdit(null)}>
                        <RequirementForm organizationId={currentUser?.organization?.id} item={showEdit} close={() => setShowEdit(null)} />
                    </SideModal>
                )}

                {showAdd && (
                    <SideModal closeModal={() => setShowAdd(false)}>
                        <RequirementForm organizationId={currentUser?.organization?.id} close={() => setShowAdd(false)} />
                    </SideModal>
                )}
            </>
        )
    }
    return null
}

export default TrainingSettings;