import React from "react";
import { renderLevelBlock } from "../utils";
import { CTC_STATUS } from "../../../utils/constants";
import { Link } from '@reach/router';
import { Clock, CheckCircle, XCircle, AlertCircle } from 'react-feather';
import moment from "moment/moment";
import styles from '../PractitionerCompliance.module.scss';
import { getLevelIntFromText } from "../../../utils/functions";

const levels = ['foundation', 'intermediate', 'advanced'];

const LeaderItem = ({ leader, trainingRequirements, globalStats }) => {

    const renderCpdBlock = () => {
        const bgCls = leader.cpdHoursTotal === 0 ? 'l-red' : leader.cpdHoursTotal < leader.cpdHoursTarget ? 'l-amber' : 'l-green'
        if (leader.practitionerLevel === null) {
            return (
                <div className={`${styles.item} mr-2`}>
                    <p className="lead bold">-/-</p>
                    <p className="small">-</p>
                </div>
            )
        }
        return (
            <div className={`${styles.item} ${bgCls} mr-2`}>
                <p className="lead bold">{leader.cpdHoursTotal}/{leader.cpdHoursTarget}</p>
                <p className="small">{leader.cpdLogsCount} Entries</p>
            </div>
        )
    }



    const getTrainingBg = (level) => {

        // treat trainee like foundation
        const practitionerLevelForMatrix = leader.practitionerLevel === 0 ? 1 : leader.practitionerLevel
        const coursesCompleted = leader[`${level}Courses`];

        const levelInt = getLevelIntFromText(level);

        const total = globalStats(level)?.required;

        if (total === 0 && coursesCompleted === 0) {
            return 'l-grey-2'
        } else
            if (coursesCompleted >= total && total !== 0) {
                return practitionerLevelForMatrix >= levelInt ? 'l-green' : 'l-grey-2'
            } else if (coursesCompleted === 0) {
                return practitionerLevelForMatrix >= levelInt ? 'l-red' : 'l-grey-2'
            } else if (coursesCompleted < total) {
                return practitionerLevelForMatrix >= levelInt ? 'l-amber' : 'l-grey-2'
            }
    }

    const renderTrainingBlocks = () => {
        return (
            <>
                {levels.map(level =>
                    <div key={`tr-${level}`} className={`${styles.item} ${getTrainingBg(level)}`}>
                        {leader.practitionerLevel !== null ?

                            <>
                                <p className="lead bold">{leader[`${level}Courses`]}/{globalStats(level)?.required}</p>
                                <p className="small">{leader[`${level}Desired`] === '0/0' ? '-' : <>{leader[`${level}Desired`]}/{globalStats(level)?.desired} desired</>}</p>
                            </> :
                            <>
                                <p className="lead bold">-/-</p>
                                <p className="small">--</p>
                            </>
                        }
                    </div>
                )}
            </>
        )
    }

    const renderCtcBlock = () => {
        switch (leader.ctcStatus) {
            case CTC_STATUS.processing:
                return (
                    <div className={`${styles.item} ${styles.itemCtc} l-amber`}>
                        <p><Clock /></p>
                        <p className="small">In progress</p>
                    </div>
                );
            case CTC_STATUS.passed:
                return (
                    <div className={`${styles.item} ${styles.itemCtc} l-green`}>
                        <p><CheckCircle /></p>
                        {leader.ctcCheckDate && <p className="small">{moment(leader.ctcCheckDate).format('DD/MM/YYYY')}</p>}
                    </div>
                );
            case CTC_STATUS.denied:
                return (
                    <div className={`${styles.item} ${styles.itemCtc} l-red`}>
                        <p><XCircle /></p>
                        <p className="small">Denied</p>
                    </div>
                );
            default:
                return (
                    <div className={`${styles.item} ${styles.itemCtc}`}>
                        <p className="lead bold"><XCircle /></p>
                        <p className="small">No check</p>
                    </div>
                )
        }
    }

    return (
        <li>
            <Link className={styles.row} to={`${leader.user.id}/cpd`}>
                <div className={styles.rowName}>
                    {leader.user?.firstName} {leader.user?.lastName}
                </div>
                <div className={styles.rowStats}>
                    <div className={`${styles.item} ${leader.practitionerLevel === null ? 'l-red' : ''}`}>
                        {renderLevelBlock(leader.practitionerLevel, <AlertCircle />)}
                    </div>
                    {renderCpdBlock()}
                    {trainingRequirements.length > 0 && renderTrainingBlocks()}
                    {renderCtcBlock()}
                </div>
            </Link>
        </li>

    )
}

export default LeaderItem;