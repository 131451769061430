export const renderLevelBlock = (level, icon) => {

    const levelMap = {
        0: { label: 'T', description: 'Trainee' },
        1: { label: 'F', description: 'level 1' },
        2: { label: 'I', description: 'level 2' },
        3: { label: 'A', description: 'level 3' },
    }

    const { label = '', description = 'No level' } = levelMap[level] || {};
    return (
        <>
            <p className="lead bold">{label || icon}</p>
            <p className="small">{description}</p>
        </>
    )
}