import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import FormErrors from "../../../../components/FormErrors";
import { ADD_TRAINING_REQUIREMENT, EDIT_TRAINING_REQUIREMENT, DELETE_TRAINING_REQUIREMENT, GET_TRAINING_REQUIREMENTS } from "../../services/graphql";
import { TR_REQUIREMENT_LEVELS } from "../../../../utils/constants";
import { capitalize } from "../../../../utils/functions";


const levels = ['foundation', 'intermediate', 'advanced'];

const RequirementForm = ({ item, close, organizationId }) => {

    const [formErrors, setFormErrors] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            courseTitle: item?.title,
            externalRequirement: item?.externalRequirement,
            requiredLevel: item?.requiredLevel,
            foundation: item?.foundation || 'none',
            intermediate: item?.intermediate || 'none',
            advanced: item?.advanced || 'none'
        }
    });

    const watchExternal = watch('externalRequirement', item?.externalRequirement);
    const [createTrainingRequirement, { loading }] = useMutation(ADD_TRAINING_REQUIREMENT, {
        update: (_, { data }) => {
            if (data.createTrainingRequirement?.errors.length > 0) {
                setFormErrors(data.createTrainingRequirement?.errors);
                return
            }
            if (data?.createTrainingRequirement?.trainingRequirement?.id) {
                close();
            }
        },
        refetchQueries: [{ query: GET_TRAINING_REQUIREMENTS, variables: { organizationId } }]
    });

    const [editTrainingRequirement, { loading: editing }] = useMutation(EDIT_TRAINING_REQUIREMENT, {
        update: (_, { data }) => {
            if (data?.editTrainingRequirement?.errors.length > 0) {
                setFormErrors(data.editTrainingRequirement.errors);
                return
            }
            if (data?.editTrainingRequirement?.trainingRequirement?.id) {
                close();
            }
        },
        refetchQueries: [{ query: GET_TRAINING_REQUIREMENTS, variables: { organizationId } }]
    });

    const [deleteTrainingRequirement, { loading: deleting }] = useMutation(DELETE_TRAINING_REQUIREMENT, {
        update: (_, { data }) => {
            if (data?.deleteTrainingRequirement?.errors.length > 0) {
                alert(data.deleteTrainingRequirement.errors);
                return
            }
            if (data?.deleteTrainingRequirement?.deleted) {
                close();
            }
        },
        refetchQueries: [{ query: GET_TRAINING_REQUIREMENTS, variables: { organizationId } }]
    });


    const onSubmit = data => {
        const { courseTitle, foundation, advanced, intermediate, externalRequirement, requiredLevel } = data;
        if (item) {
            editTrainingRequirement({
                variables: {
                    trainingRequirementId: item.id,
                    courseTitle,
                    externalRequirement,
                    requiredLevel,
                    foundation: foundation === 'none' ? null : +foundation,
                    advanced: advanced === 'none' ? null : +advanced,
                    intermediate: intermediate === 'none' ? null : +intermediate,
                }
            });
        } else {
            createTrainingRequirement({
                variables: {
                    organizationId,
                    courseTitle,
                    externalRequirement,
                    requiredLevel,
                    foundation: foundation === 'none' ? null : +foundation,
                    advanced: advanced === 'none' ? null : +advanced,
                    intermediate: intermediate === 'none' ? null : +intermediate,
                }
            });
        }
    }

    return (
        <>

            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>{item ? 'Edit' : 'Add'} Training Requirement</h1>
                <div className="basic-form__group">
                    <label htmlFor="courseTitle">Course Title </label>
                    <input placeholder="e.g. Module 1 - Introduction to restorative Practice" defaultValue={item?.courseTitle} type="text" {...register('courseTitle', { required: 'Please enter a title' })} className="basic-form__text-box" />
                    {errors.courseTitle && <p className="basic-form__hint">{errors.courseTitle.message}</p>}
                </div>

                <div className="d-flex align-items-center mb-2">
                    <input id="externalRequirement" type="checkbox" {...register('externalRequirement')} className="basic-check-box mr-2" />
                    <label htmlFor="externalRequirement">External Requirement or Qualification</label>
                </div>

                {watchExternal &&
                    <div className="basic-form__group">
                        <label htmlFor="requiredLevel">Required Level (Optional) </label>
                        <input placeholder="e.g. ONC Level 2 or Equivalent" type="text" {...register('requiredLevel')} className="basic-form__text-box" />
                    </div>
                }
                {levels.map(level => (
                    <div key={level} className="basic-form__group">
                        <label htmlFor="type">{capitalize(level)}</label>
                        <select {...register(level, { required: `Please select a value for ${level} ` })} className="basic-form__text-select">
                            <option value={''}>Please select</option>
                            <option value={'none'}>No Requirement</option>
                            {Object.entries(TR_REQUIREMENT_LEVELS).map(el => {
                                return <option key={`opt${el[0]}`} value={el[1]}>{capitalize(el[0])}</option>
                            }
                            )}
                        </select>
                        {errors[level] && <p className="basic-form__hint">{errors[level].message}</p>}
                    </div>
                ))}


                {formErrors && <FormErrors errors={formErrors} />}
                <button disabled={editing || loading} className="btn">Save</button>
                <button type="button" onClick={close} className="btn btn-outline ml-2">Cancel</button>


            </form>
            {item &&
                <>
                    <hr className="separator mb-4" />
                    {showConfirm ?
                        <>
                            <p className="bold mt-3 mb-1">Are you sure you want to delete this course?</p>
                            <button disabled={deleting} type="button" onClick={() => deleteTrainingRequirement({
                                variables: {
                                    trainingRequirementId: item.id
                                }
                            })} className="btn btn-outline">Yes</button>
                            <button type="button" onClick={() => setShowConfirm(false)} className="btn btn-outline ml-2">No</button>

                        </> : <button type="button" onClick={() => setShowConfirm(true)} className="btn btn-danger">Delete</button>
                    }
                </>
            }
        </>
    )
}

export default RequirementForm;