import React, { useState, useContext } from "react";
import SideModal from "../../../components/SideModal";
import { Plus } from "react-feather";
import LogForm from "./LogForm";
import LogItem from "./LogItem";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_CPD_LOGS, GET_TRAINING_HEADER_INFO } from "./services/graphql";
import TrainingHeader from "../TrainingHeader";
import ViewMember from "../../Team/components/ViewMember";
import { AuthContext } from "../../../services/auth/AuthProvider";
import Loader from '../../../components/Loader';
import moment from "moment";
import SaveConfirm from "../../../components/SaveConfirm";
import styles from '../TrainingRec.module.scss'

const CpdContent = ({ userId, levelDate, fromView }) => {

    const { currentUser } = useContext(AuthContext);
    const [showAdd, setShowAdd] = useState(false);
    const [editLog, setEditLog] = useState(null);
    const [logInfo, setLogInfo] = useState(null);
    const [selectedRange, setSelectedRange] = useState(levelDate ? 'curr_aniv' : 'curr_cal');

    const [showConfirmAdd, setShowConfirmAdd] = useState(false);

    const [showEdit, setShowEdit] = useState(false);

    function getAnniversaryYear(dateString, getPrevious) {
        const inputDate = moment(dateString, "YYYY-MM-DD");
        const now = moment();
        // if present date is before inputDate updated with the current Year, curr aniv year is prev year, else current year
        const anniversaryYear = now.isBefore(inputDate.clone().year(now.year())) ?
            now.year() - (getPrevious ? 2 : 1) : now.year() - (getPrevious ? 1 : 0);

        const start = inputDate.clone().year(anniversaryYear);
        const end = start.clone().add(1, "year").subtract(1, "day");
        return {
            start: start.format("DD MMM YYYY"),
            end: end.format("DD MMM YYYY"),
        };
    }


    const getDates = (selectedRange) => {
        switch (selectedRange) {
            case 'curr_aniv':
                return {
                    start: getAnniversaryYear(levelDate).start,
                    end: getAnniversaryYear(levelDate).end
                }
            case 'prev_aniv':
                return {
                    start: getAnniversaryYear(levelDate, true).start,
                    end: getAnniversaryYear(levelDate, true).end
                }
            case 'curr_cal':
                return {
                    start: moment().startOf('year').format('DD MMM YYYY'),
                    end: moment().format('DD MMM YYYY')
                }
            case 'prev_cal':
                return {
                    start: moment().subtract(1, 'year').startOf('year').format('DD MMM YYYY'),
                    end: moment().subtract(1, "year").endOf("year").utc().format('DD MMM YYYY')
                }
            default:
                return {}
        }
    }

    const { start, end } = getDates(levelDate ? 'curr_aniv' : 'curr_cal');

    const { data, loading, error } = useQuery(GET_CPD_LOGS, {
        fetchPolicy: 'network-only',
        variables: {
            id: userId,
            startDate: start,
            endDate: end
        },
        onCompleted: data => setLogInfo(data?.user?.cpdLogInfo)
    });

    const [fetchLogs, { loading: fetching }] = useLazyQuery(GET_CPD_LOGS, {
        fetchPolicy: 'network-only',
        onCompleted: data => setLogInfo(data?.user?.cpdLogInfo)
    });

    const { data: dataHeader, loading: loadingHeader } = useQuery(GET_TRAINING_HEADER_INFO, {
        variables: {
            id: userId,
        }
    });


    const onAddLog = log => {
        const { start, end } = getDates(selectedRange);
        const isInRange = moment(log.date, 'YYYY-MM-DD').isBetween(moment(start, 'DD MMM YYYY'), moment(end, 'DD MMM YYYY'), null, '[]');
        if (isInRange) {
            setLogInfo({ ...logInfo, logs: [...logInfo.logs, log] });
        }
        setShowConfirmAdd(true);
    }

    const onEditLog = log => {
        const isInRange = moment(log.date, 'YYYY-MM-DD').isBetween(moment(start, 'DD MMM YYYY'), moment(end, 'DD MMM YYYY'), null, '[]');
        const updatedLogs = isInRange ? logInfo.logs.map(el => el.id === log.id ? log : el) : logInfo.logs.filter(el => el.id !== log.id);
        setLogInfo({ ...logInfo, logs: [...updatedLogs] });
    }

    const getHours = logs => {
        const hrs = logs.map(l => l.hours);
        return hrs.reduce((a, v) => a + v, 0);
    }

    const onChangeFilter = ({ target: { value } }) => {
        setSelectedRange(value);
        const { start, end } = getDates(value);
        fetchLogs({
            variables: {
                id: userId,
                startDate: start,
                endDate: end
            }
        });
    }


    if (showEdit && userId) {
        return (
            <ViewMember
                user={{ id: userId }}
                close={() => setShowEdit(false)}
                currentUser={currentUser}
                organization={currentUser.organization}
            />
        )
    }

    if (error) return <p>{error.message}</p>
    if (loading || loadingHeader) return <Loader />

    if (data?.user?.cpdLogInfo && logInfo && dataHeader?.user) {
        return (
            <>
                <TrainingHeader
                    fromView={fromView}
                    setShowEdit={setShowEdit}
                    currentUser={currentUser}
                    userData={dataHeader.user}
                />
                <h3>CPD</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="mb-2 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <select value={selectedRange} onChange={(e) => onChangeFilter(e)} className="basic-form__text-select">
                                    {data.user.practitionerLevel !== null &&
                                        <option value='curr_aniv'>Current Anniversary year ({getDates('curr_aniv').start} - {getDates('curr_aniv').end})</option>
                                    }
                                    <option value='curr_cal'>Current Calendar year ({getDates('curr_cal').start} - {getDates('curr_cal').end})</option>
                                    {data.user.practitionerLevel !== null &&
                                        <option value='prev_aniv'>Prev Anniversary year ({(getDates('prev_aniv').start)} - {(getDates('prev_aniv').end)})</option>
                                    }
                                    <option value='prev_cal'>
                                        Prev Calendar Year (
                                        {getDates('prev_cal').start} -
                                        {getDates('prev_cal').end})
                                    </option>
                                </select>
                                {logInfo?.logs?.length > 0 &&
                                    <>
                                        <span className={styles.entries}>
                                            <p className={styles.entriesTitle}>{getHours(logInfo.logs)} hrs</p>
                                            <span>Total time</span>
                                        </span>
                                        <span className={styles.entries}>
                                            <p className={styles.entriesTitle}>{logInfo?.logs?.length}</p>
                                            <span>Total entries</span>
                                        </span>
                                    </>
                                }
                            </div>

                            {!fromView &&
                                <button
                                    type="button"
                                    onClick={() => setShowAdd(true)}
                                    className="btn d-flex px-1 ml-1 btn-primary"
                                ><Plus /></button>
                            }
                        </div>

                        {showConfirmAdd && <SaveConfirm message={'Log added!'} hideConfirm={() => setShowConfirmAdd(false)} />}

                        {fetching ? <Loader /> :
                            <>
                                {logInfo?.logs?.length > 0 ?
                                    <ul>
                                        {logInfo?.logs.map(log =>
                                            <LogItem fromView={fromView} key={log.id} onEdit={() => setEditLog(log)} log={log} />
                                        )}
                                    </ul> : <p>No CPD logs have been added during this period</p>}
                            </>
                        }
                    </div>
                </div>
                {showAdd && (
                    <SideModal closeModal={() => setShowAdd(false)}>
                        <LogForm selectedRange={selectedRange} userId={userId} onAddLog={onAddLog} close={() => setShowAdd(false)} />
                    </SideModal>
                )}
                {editLog && (
                    <SideModal closeModal={() => setEditLog(null)}>
                        <LogForm selectedRange={selectedRange} userId={userId} onEditLog={onEditLog} log={editLog} close={() => setEditLog(null)} />
                    </SideModal>
                )}
            </>
        )
    }
    return null;
}

export default CpdContent;