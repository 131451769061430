import React, { useState } from "react";
import { QUALIFICATIONS_EXPERIENCES, EDIT_QUALIFICATIONS_EXPERIENCES, VIEW_USER } from "../services/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Edit, Loader, Star, Award } from "react-feather";
import FormErrors from "../../../components/FormErrors";
import styles from '../Team.module.scss';

const CATEG_TYPES = {
    'experiences': 1,
    'qualifications': 2,
}

const QualificationsExperience = ({ user, currentUser }) => {

    const [showEditExp, setShowEditExp] = useState(false);
    const [showEditQualifications, setShowEditQualifications] = useState(false);

    const [mutationErrors, setMutationErrors] = useState([]);
    const [mutationExpErrors, setMutationExpErrors] = useState([]);

    const [userExperiences, setUserExperiences] = useState([...user.userProfile?.experiences.map(exp => exp.id) || []]);
    const [userQualifications, setUserQualifications] = useState([...user.userProfile?.qualifications.map(q => q.id) || []]);


    const { data, loading: loadingExp, error } = useQuery(QUALIFICATIONS_EXPERIENCES);

    const [editQualificationsExperiences, { loading: editing }] = useMutation(EDIT_QUALIFICATIONS_EXPERIENCES, {
        update: (_, { data }) => {
            if (data?.updateProfileDbsQualificationsExperiences?.errors?.length > 0) {
                const err = data.updateProfileDbsQualificationsExperiences.errors;
                showEditExp ? setMutationExpErrors(err) : setMutationErrors(err)
                return;
            }
            if (data?.updateProfileDbsQualificationsExperiences?.userProfile?.id) {
                showEditExp && setShowEditExp(false);
                showEditQualifications && setShowEditQualifications(false);
                setMutationErrors([]);
                setMutationExpErrors([]);
            }
        },
        refetchQueries: [{ query: VIEW_USER, variables: { id: user.id } }]
    });

    const onSaveEdit = (categType) => {
        if (categType === CATEG_TYPES.experiences) {
            editQualificationsExperiences({
                variables: {
                    userId: user.id,
                    experiences: userExperiences.map(el => ({ qualificationExperience: el }))
                }
            })
        }
        else {
            editQualificationsExperiences({
                variables: {
                    userId: user.id,
                    qualifications: userQualifications.map(el => ({ qualificationExperience: el }))
                }
            })
        }
    }

    const handleCategoryChange = (e, itemId, categType) => {
        if (e.target.checked) {
            categType === CATEG_TYPES.experiences ? setUserExperiences([...userExperiences, itemId]) : setUserQualifications([...userQualifications, itemId])
        } else {
            categType === CATEG_TYPES.experiences ? setUserExperiences(userExperiences.filter(el => el !== itemId)) : setUserQualifications(userQualifications.filter(el => el !== itemId))
        }
    }

    if (loadingExp) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }
    if (data?.qualificationsAndExperiences) {

        const { qualificationsAndExperiences: { qualifications, experiences } } = data;


        return (
            <div className="row mb-3 mt-2">
                <div className="col-md-6">
                    <div className="mb-2 d-flex align-items-center justify-content-between">
                        <p className="uppercase bold small text-muted-2">Qualifications</p>
                        {currentUser.isOrganizationAdmin &&
                            <button className="d-flex align-items-center" onClick={() => setShowEditQualifications(true)}><Edit className="mr-1 text-primary" /> Edit</button>
                        }
                    </div>
                    {showEditQualifications ?
                        <>
                            <ul className="list-group">
                                {qualifications.map(item => (
                                    <li className="list-item d-block mb-1" key={`q-${item.id}`}>
                                        <div className="basic-form__group basic-form__group--check mb-0">
                                            <span>{item.name}</span>
                                            <div className="ml-auto d-flex">
                                                <input
                                                    type="checkbox"
                                                    className="switchToggle"
                                                    id={`q-${item.id}`}
                                                    checked={userQualifications.includes(item.id)} onChange={(e) => handleCategoryChange(e, item.id, CATEG_TYPES.qualifications)}
                                                />
                                                <label className="switchLabel" htmlFor={`q-${item.id}`}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <FormErrors errors={mutationErrors || []} />
                            <button className="btn mr-2" disabled={editing} onClick={() => onSaveEdit(CATEG_TYPES.qualifications)}>Save</button>
                            <button className="btn btn-outline" onClick={() => setShowEditQualifications(false)}>Cancel</button>
                        </> :
                        <ul>
                            {user.userProfile?.qualifications.length > 0 ? user.userProfile.qualifications.map(item => (
                                <li key={item.id} className={`${styles.experience} bold d-flex align-items-center px-1 py-1 }`}><Award /> {item.name}</li>
                            )) : <p>No qualifications added</p>}
                        </ul>
                    }
                </div>

                <div className="col-md-6">
                    <div className="mb-2 d-flex align-items-center justify-content-between">
                        <p className="uppercase bold small text-muted-2">Experience</p>
                        {currentUser.isOrganizationAdmin &&
                            <button className="d-flex align-items-center" onClick={() => setShowEditExp(true)}><Edit className="mr-1 text-primary" /> Edit</button>
                        }
                    </div>
                    {showEditExp ?
                        <>
                            <ul className="list-group">
                                {experiences.map(item => (
                                    <li className="list-item d-block mb-1" key={`exp-${item.id}`}>
                                        <div className="basic-form__group basic-form__group--check mb-0">
                                            <span>{item.name}</span>
                                            <div className="ml-auto d-flex">
                                                <input
                                                    type="checkbox"
                                                    className="switchToggle"
                                                    id={`exp-${item.id}`}
                                                    checked={userExperiences.includes(item.id)}
                                                    onChange={(e) => handleCategoryChange(e, item.id, CATEG_TYPES.experiences)}
                                                />
                                                <label className="switchLabel" htmlFor={`exp-${item.id}`}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <FormErrors errors={mutationExpErrors || []} />
                            <button className="btn mr-2" disabled={editing} onClick={() => onSaveEdit(CATEG_TYPES.experiences)}>Save</button>
                            <button className="btn btn-outline" onClick={() => setShowEditExp(false)}>Cancel</button>
                        </> :
                        <ul>
                            {user.userProfile?.experiences.length > 0 ? user.userProfile?.experiences.map(item => (
                                <li key={item.id} className={`${styles.experience} bold d-flex align-items-center px-1 py-1 }`}><Star /> {item.name}</li>
                            )) : <p> No experiences added</p>}
                        </ul>
                    }
                </div>

            </div>
        )
    }
    return null;
}

export default QualificationsExperience;