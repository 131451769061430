import React, { useState, useContext } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { ADD_TIME_LOG_OR_CASE_NOTE, GET_CASE_NOTES, GET_CIRCLE_TIME_LOGS } from "../../../../services/grapqhl";
import { AuthContext } from "../../../../../../services/auth/AuthProvider";
import FormErrors from "../../../../../../components/FormErrors";
import { GET_S3_UPLOAD_URL_QUERY } from "../../../../../ResourceCentre/services/graphql";
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from "moment";
import { AlertTriangle, Paperclip } from "react-feather";

const AddNote = ({ circle, close }) => {

    const { currentUser } = useContext(AuthContext);
    const client = useApolloClient();

    const [initLoading, setInitLoading] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [formWarning, setFormWarning] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const onSelectFile = (files) => {
        if (files.length) {
            setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
            const currentDocuments = getValues('documents');
            const index = currentDocuments ? currentDocuments.length : 0;
            append({ name: files[0]?.name || '', url: '' });
            setValue(`documents.${index}.name`, files[0]?.name);
        } 
    }


    const [addTimeLogOrCaseNote, { loading }] = useMutation(ADD_TIME_LOG_OR_CASE_NOTE, {
        onCompleted: (data) => {
            if (data?.addTimeLogOrCaseNote?.errors?.length > 0) {
                setFormErrors(data.addTimeLogOrCaseNote.errors)
                return
            }
            if (data?.addTimeLogOrCaseNote?.caseNote?.id || data.addTimeLogOrCaseNote?.log?.id) {
                close();
                setSelectedFiles([]);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: () => [
            {
                query: GET_CASE_NOTES,
                variables: {
                    circleId: circle.id,
                },
                fetchPolicy: 'network-only'
            },
            {
                query: GET_CIRCLE_TIME_LOGS,
                variables: {
                    circleId: circle.id
                }
            }
        ],
    })


    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        getValues,
        formState: {
            errors,
        },
    } = useForm({
        mode: 'onSubmit',
    });


    const { fields, append } = useFieldArray({
        control,
        name: 'documents',
    });

    const onSubmit = data => {
        const { text, duration, logDate } = data;
        if ((text === '' && selectedFiles.length === 0 && duration === '')) {
            setFormWarning(true);
            return;
        } else {
            setFormWarning(false);
        }

        if (selectedFiles.length > 0) {
            const uploadPromises = selectedFiles.map(async (selectedFile, index) => {
                const extension = selectedFile.name.split('.').pop().toLowerCase();
                const filename = `Document-${new Date().getTime()}.${extension}`;
                setInitLoading(true);
                try {
                    const r = await client.query({
                        query: GET_S3_UPLOAD_URL_QUERY,
                        variables: {
                            fileName: filename
                        }
                    });
                    const options_1 = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } };
                    await axios.put(r.data.s3UploadUrl, selectedFile, options_1);
                    const documents = watch('documents');
                    return {
                        name: documents[index]?.name,
                        url: r.data.s3UploadUrl.substr(0, r.data.s3UploadUrl.indexOf('?'))
                    };
                } catch (error) {
                    console.error('Error uploading file:', selectedFile.name, error);
                    return null;
                }
            });

            Promise.all(uploadPromises).then(uploadedDocuments => {
                return addTimeLogOrCaseNote({
                    variables: {
                        circleId: circle.id,
                        ...(text && { text: text, authorId: currentUser.id }),
                        ...(duration && { duration: parseFloat(duration) }),
                        ...(logDate && { logDate: moment(logDate).format('DD/MM/YYYY') }),
                        documents: uploadedDocuments.filter(d => d),
                    },
                });
            }).then(() => {
                setInitLoading(false);
            }).catch(error => {
                setInitLoading(false);
                console.error('Error uploading files', error);
            });

        } else {
            addTimeLogOrCaseNote({
                variables: {
                    circleId: circle.id,
                    ...(text && { text: text, authorId: currentUser.id }),
                    ...(duration && { duration: parseFloat(duration) }),
                    ...(logDate && { logDate: moment(logDate).format('DD/MM/YYYY') }),
                }
            });
        }
    }

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-md-7">
                    <h2>Add Note</h2>
                    <p>Optional: Leave blank to add Time Log only</p>
                    <div className="basic-form__group">
                        <textarea
                            {...register('text')}
                            className="basic-form__text-box"
                            placeholder="Add your case note here"
                            rows={8}></textarea>
                    </div>

                    {fields.map((document, index) => (
                        <div key={`section-${index}`} className="mb-2">
                            <label htmlFor={`documents.${index}.name`}>Document name</label>
                            <input
                                type="text"
                                className="basic-form__text-box"
                                defaultValue={document.name}
                                {...register(`documents.${index}.name`, { required: 'Please add a name' })}
                            />
                            {errors?.documents?.[index]?.name?.message && <p className="basic-form__hint">{errors?.documents?.[index]?.name?.message}</p>}
                        </div>
                    ))}

                    <div>
                        <input
                            className='inputFile'
                            id='list'
                            name='list'
                            type='file'
                            onChange={e => onSelectFile(e.target.files)}
                        />
                        <label htmlFor='list'><Paperclip size='20' className="mr-1" />Attach a document/photo</label>
                    </div>
                </div>
                <div className="col-md-5">
                    <h2>Add Time Log</h2>
                    <p>Optional: Leave blank to add Case Note only</p>
                    <div className="basic-form__group flex-row align-items-center">
                        <input
                            {...register('duration')}
                            className="basic-form__text-box mb-0"
                            placeholder="e.g. 0.5" />
                        <span className="ml-2">Hours</span>
                    </div>

                    <div className="basic-form__group">
                        <p>Date of logged hours</p>
                        <Controller
                            control={control}
                            name="logDate"
                            defaultValue={''}
                            rules={{
                                validate: value => {
                                    const duration = watch('duration');
                                    if (duration && !value) {
                                        return "Please enter a date for the time log!";
                                    }
                                    return true;
                                }
                            }}
                            render={({ field }) => (
                                <ReactDatePicker
                                    {...field}
                                    dateFormat="dd/MM/yyyy"
                                    className="basic-form__text-box picker"
                                    placeholderText="Select date"
                                    autoComplete="off"
                                    maxDate={moment().toDate()}
                                    selected={field.value}
                                    ref={(ref) => {
                                        if (ref && ref.input) {
                                            field.ref(ref.input);
                                        }
                                    }}
                                />
                            )}
                        />
                        {errors.logDate && <p className="basic-form__hint">{errors.logDate.message}</p>}

                    </div>
                </div>
            </div>
            <hr className="separator mt-3 mb-3" />
            {formErrors && <FormErrors errors={formErrors} />}
            {formWarning && <p className="d-flex align-items-center mb-2 text-danger"><AlertTriangle className="mr-2" />Enter either a note or a time log!</p>}
            {initLoading && <p>Loading documents...</p>}
            <button disabled={initLoading || loading} className="btn mr-2">Save</button>
            <button className="btn btn-muted" onClick={() => close()} type="button">Cancel</button>
        </form>
    </>;
}

export default AddNote;