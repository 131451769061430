import { gql } from "@apollo/client";

export const GET_CPD_LEVEL_DATE = gql`
query user($id: ID!) {
    user(id: $id) {
        id
        practitionerLevelDate
    }
}
`;

export const GET_CPD_LOGS = gql`
query user($id: ID!, $startDate: String, $endDate:String) {
    user(id: $id) {
        id
        practitionerLevelDate
        practitionerLevel
        cpdLogInfo(startDate: $startDate, endDate: $endDate) {
            totalEntries
            totalHours
            logs {
                id
                date
                cpdType
                description
                hours
                reflection
            }
        }
    }
}
`;



export const GET_TRAINING_HEADER_INFO = gql`
query user($id: ID!) {
    user(id: $id) {
        id
        practitionerLevel
        practitionerLevelDate
        firstName
        lastName
        userProfile {
            id
            backgroundChecks {
                id
                ctcStatus
                ctcCheckDate
            }
        }
        practitionerCompliance {
            id
            cpdHoursTarget
            cpdHoursTotal
            cpdLogsCount
        }
        organization {
            id
            trainingRequirements {
                id
                foundation
                intermediate
                advanced
                courseTitle
            }
        }
        userTrainingRequirements {
            id
            currentMatch
            status
            trainingRequirement {
                id
            }
        }
    }
}
`;

export const CREATE_CPD_LOG = gql`
mutation createCpdLog($userId: ID!, $date: String!, $cpdType: String!, $description: String, $hours: Float!, $reflection: String) {
    createCpdLog(userId: $userId, date: $date, cpdType: $cpdType, description: $description, hours: $hours, reflection: $reflection) {
        errors
        cpdLog {
            id
            date
            cpdType
            description
            hours
            reflection
        }
    }
}
`;

export const EDIT_CPD_LOG = gql`
mutation editCpdLog($cpdLogId: ID!, $date: String!, $cpdType: String!, $description: String!, $hours: Float!, $reflection: String) {
    editCpdLog(cpdLogId: $cpdLogId, date: $date, cpdType: $cpdType, description: $description, hours: $hours, reflection: $reflection) {
        errors
        cpdLog {
            id
            date
            cpdType
            description
            hours
            reflection
        }
    }
}
`;