import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_OFFICE_CASES } from './services/grapqhl';
import Loader from '../../components/Loader';
import CaseItem from './components/CaseItem';
import AddCase from './components/AddCase';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs';

const TABS = {
    activeCases: 1,
    archivedCases: 2,
}

const Cases = ({ officeId }) => {

    const [selectedTab, setSelectedTab] = useState(TABS.activeCases);
    const [sortedCases, setSortedCases] = useState([]);
    const [baseCases, setBaseCases] = useState(sortedCases);

    const [addCase, setAddCase] = useState(false);
    const [sortType, setSortType] = useState('');

    const [getCases, { data, loading, error }] = useLazyQuery(GET_OFFICE_CASES, {
        fetchPolicy: 'network-only',
        variables: {
            officeId,
        },
        onCompleted: data => {
            setBaseCases(data.office?.circles);
        }
    });


    useEffect(() => {
        if (data?.office && data.office.circles !== baseCases) {
            setBaseCases(data.office.circles);
        }
    }, [data, baseCases]);


    useEffect(() => {
        getCases({
            variables: {
                officeId,
                archived: selectedTab === TABS.archivedCases
            }
        })
    }, [selectedTab, getCases, officeId]);

    useEffect(() => {
        if (sortType === 'oldest') {
            setSortedCases([...baseCases].sort((a, b) => a.createdAt.localeCompare(b.createdAt)))
        } else if (sortType === 'recent') {
            setSortedCases([...baseCases].sort((a, b) => b.createdAt.localeCompare(a.createdAt)))
        } else if (sortType === 'caseType') {
            setSortedCases([...baseCases].sort((a, b) => (a.caseType === null) - (b.caseType === null) || +(a.caseType > b.caseType) || -(a.caseType < b.caseType)));
        } else if (sortType === 'nameAsc') {
            setSortedCases([...baseCases].sort((a, b) => ((a.name || '').localeCompare(b.name))))
        } else if (sortType === 'nameDesc') {
            setSortedCases([...baseCases].sort((a, b) => ((b.name || '').localeCompare(a.name))))
        } else if (sortType === 'caseNumber') {
            setSortedCases([...baseCases].sort((a, b) => a.caseNumber.localeCompare(b.caseNumber)))
        } else {
            setSortedCases(baseCases);
        }
    }, [sortType, baseCases]);


    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <Loader />
    }

    if (data && data.office?.id) {

        return (
            <>
                {addCase ? <AddCase officeId={officeId} onCancel={() => setAddCase(false)} /> :
                    <>
                        <h2>Cases</h2>
                        <div className="d-sm-flex justify-content-between">
                            <Tabs withPanel className='u-m-base-3'>
                                <Tab onClick={() => setSelectedTab(TABS.activeCases)} selected={selectedTab === TABS.activeCases}>Active cases</Tab>
                                <Tab onClick={() => setSelectedTab(TABS.archivedCases)} selected={selectedTab === TABS.archivedCases}>Archived cases</Tab>
                                <select defaultValue={sortType} className='basic-form__text-select' onChange={(e) => setSortType(e.target.value)}>
                                    <option value="">View by</option>
                                    <option value="recent">Most recent</option>
                                    <option value="nameAsc">Name (Asc)</option>
                                    <option value="nameDesc">Name (Desc)</option>
                                    <option value="oldest">Oldest</option>
                                    <option value="caseType">Case Type</option>
                                    <option value="caseNumber">Case Number</option>
                                </select>
                            </Tabs>
                            <button onClick={() => setAddCase(true)} className="btn btn-primary">+ Case</button>
                        </div>
                        {
                            sortedCases.length > 0 ? (
                                <ul className='row mt-3'>
                                    {
                                        sortedCases.map(caseItem => (
                                            <div key={caseItem.id} className='col-md-3 col-sm-4 d-flex mb-2'>
                                                <CaseItem officeId={officeId} caseItem={caseItem} />
                                            </div>
                                        ))
                                    }
                                </ul>
                            ) : <p>No cases</p>
                        }
                    </>
                }
            </>
        )
    }
    return null;
}

export default Cases;
