import { gql } from "@apollo/client";


const userTrainingRequirement = `
id
attendedTraining
holdsEquivalentQualification
dateAttended
status
declineDate
currentMatch
declinedBy {
    id
    firstName
    lastName
}
documents {
    id
    url
}
trainingQualification {
    id
    dateAttended
    level
    name
    trainingProvider
    documents {
        id
        url
    }
}
`

export const GET_TRAINING_REQUIREMENTS = gql`
query user($id: ID!, $userId: ID) {
    user(id: $id) {
        id
        practitionerLevel
        organization {
            id
            trainingRequirements {
                id
                courseTitle
                foundation
                intermediate
                advanced
                requiredLevel
                externalRequirement
                declinedUserTrainingRequirements(userId: $userId) {
                    ${userTrainingRequirement}
                }
                userTrainingRequirements(userId: $userId) {
                     ${userTrainingRequirement}
                }
            }
        }
        trainingQualifications {
            id
            dateAttended
            level
            name
            trainingProvider
        }
    }
}
`;

export const ADD_USER_TRAINING_REQUIREMENT = gql`
mutation addUserTrainingRequirementWithQualification($userId: ID!, $trainingRequirementId: ID!, $attendedTraining: Boolean, $holdsEquivalentQualification: Boolean, $dateAttended: String, $document: TrainingDocumentInput, $trainingQualificationId: ID, $name: String, $trainingProvider: String, $level: String) {
    addUserTrainingRequirementWithQualification(userId: $userId, trainingRequirementId: $trainingRequirementId, attendedTraining: $attendedTraining, holdsEquivalentQualification: $holdsEquivalentQualification, dateAttended: $dateAttended, document: $document, trainingQualificationId: $trainingQualificationId,name:$name, trainingProvider: $trainingProvider, level: $level ) {
        errors
        trainingQualification {
            id
        }
        userTrainingRequirement {
            id
        }
    }
} 
`;


export const DELETE_TRAINING_REQ_QUALIFICATION_AND_USER_TR_REQ = gql`
mutation deleteTrainingQualificationAndUserTrainingRequirements($trainingQualificationId: ID!) {
    deleteTrainingQualificationAndUserTrainingRequirements(trainingQualificationId: $trainingQualificationId) {
        errors
        deleted
    }
}
`;

export const DELETE_TRAINING_REQ_OR_QUALIFICATION = gql`
mutation deleteTrainingRequirementOrQualification($userTrainingRequirementId: ID!) {
    deleteTrainingRequirementOrQualification(userTrainingRequirementId: $userTrainingRequirementId) {
        errors
        deleted
    }
}
`;


export const EDIT_TRAINING_REQ_QUALIFICATION = gql`
mutation editTrainingRequirementOrQualification($userTrainingRequirementId: ID!, $dateAttended: String, $document: TrainingDocumentInput) {
    editTrainingRequirementOrQualification(userTrainingRequirementId: $userTrainingRequirementId, dateAttended: $dateAttended, document: $document) {
        errors
        userTrainingRequirement {
            id
        }
    }
}
`;


export const REINSTATE_USER_TR_REQ = gql`
mutation reinstateUserTrainingRequirement($userTrainingRequirementId: ID!) {
    reinstateUserTrainingRequirement(userTrainingRequirementId: $userTrainingRequirementId) {
        errors
        userTrainingRequirement {
            id
            status
        }
    }
}
`;

export const DECLINE_USER_TR_REQ = gql`
mutation declineUserTrainingRequirement($userTrainingRequirementId: ID!) {
    declineUserTrainingRequirement(userTrainingRequirementId: $userTrainingRequirementId) {
        errors
        userTrainingRequirement {
            id
            status
        }
    }
}
`;
