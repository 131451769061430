import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TRAINING_HEADER_INFO } from '../Cpd/services/graphql';
import { GET_TRAINING_REQUIREMENTS, DELETE_TRAINING_REQ_QUALIFICATION_AND_USER_TR_REQ } from "./services/graphql";
import TrainingHeader from "../TrainingHeader";
import { AuthContext } from "../../../services/auth/AuthProvider";
import RequirementItem from "./RequirementItem";
import FormErrors from "../../../components/FormErrors";
import Modal from '../../../components/Modal';
import { Trash } from "react-feather";
import Loader from '../../../components/Loader';
import ViewMember from "../../Team/components/ViewMember";
import moment from "moment";
import styles from '../TrainingRec.module.scss';

const TrainingRecLevelContent = ({ userId, fromView }) => {

    const { currentUser } = useContext(AuthContext);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const [showEdit, setShowEdit] = useState(false);


    const { data: dataHeader, loading: loadingHeader } = useQuery(GET_TRAINING_HEADER_INFO, {
        variables: {
            id: userId
        }
    });

    const [deleteTrainingQualificationAndUserTrainingRequirements, { loading: deleting }] = useMutation(DELETE_TRAINING_REQ_QUALIFICATION_AND_USER_TR_REQ, {
        onCompleted: data => {
            if (data?.deleteTrainingQualificationAndUserTrainingRequirements?.errors?.length > 0) {
                setFormErrors(data.deleteTrainingQualificationAndUserTrainingRequirements.errors)
                return
            }
            if (data.deleteTrainingQualificationAndUserTrainingRequirements?.deleted) {
                setConfirmDelete(null);
            }
        },
        refetchQueries: [{ query: GET_TRAINING_REQUIREMENTS, variables: { id: userId, userId: userId } }]
    });


    const { data, loading } = useQuery(GET_TRAINING_REQUIREMENTS, {
        variables: {
            id: userId,
            userId: userId,
        }
    });

    if (showEdit && userId) {
        return (
            <ViewMember
                user={{ id: userId }}
                close={() => setShowEdit(false)}
                currentUser={currentUser}
                organization={currentUser.organization}
            />
        )
    }

    if (loading || loadingHeader) {
        return <Loader />
    }

    if (dataHeader?.user && data?.user) {

        const { user } = data || {};
        const orgReq = user.organization?.trainingRequirements || [];
        const userQualifications = user?.trainingQualifications || [];

        return (
            <>
                <TrainingHeader
                    fromView={fromView}
                    currentUser={currentUser}
                    setShowEdit={setShowEdit}
                    userData={dataHeader.user}
                />

                <h3>Training Requirements</h3>
                <div className="card mb-3">
                    <div className="card-body">
                        <ul className={styles.reqHeader}>
                            <li className={styles.reqHeaderEl}>F (L1)</li>
                            <li className={styles.reqHeaderEl}>I (L2)</li>
                            <li className={styles.reqHeaderEl}>A (L3)</li>
                        </ul>
                        {orgReq.map(item => (
                            <RequirementItem
                                userId={userId}
                                practitionerLevel={data.user.practitionerLevel}
                                key={item.id}
                                item={item}
                                userQualifications={userQualifications}
                                fromView={fromView}
                            />)
                        )}
                    </div>
                </div>
                <h3>Equivalent Qualifications & Courses</h3>
                {userQualifications.length === 0 ? <p>No Equivalent Qualifications & Courses</p> :
                    <ul className="list-group card p-2">
                        {userQualifications.map(qualification =>
                            <li key={qualification.id} className={` list-item list-item--separator ${styles.eqCourses}`}>
                                <div className="d-flex width-full py-1 justify-content-between align-items-start">
                                    <div>
                                        <p className="lead"> {qualification.name} ({qualification.level})</p>
                                        <p className="">{qualification.trainingProvider}</p>
                                    </div>
                                    <div className="d-flex align-items-center flex-shrink-0">
                                        <span className="mr-3">{moment(qualification.dateAttended, "YYYY-MM-DD").format('DD MMM YYYY')}</span>
                                        {!fromView &&
                                            <span>
                                                <button onClick={() => setConfirmDelete(qualification)}>
                                                    <Trash />
                                                </button>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                }

                {confirmDelete && (
                    <Modal closeModal={() => setConfirmDelete(null)}>
                        <div className="text-center">
                            <p className="bold lead">{confirmDelete.name}</p>
                            <p className="bold mb-3">Are you sure you want to delete this qualification?</p>
                            {formErrors && <FormErrors errors={formErrors} />}
                            <button disabled={deleting} className="mr-2 btn" onClick={() => deleteTrainingQualificationAndUserTrainingRequirements({
                                variables: {
                                    trainingQualificationId: confirmDelete.id
                                }
                            })}>Yes</button>
                            <button className="btn btn-outline" onClick={() => setConfirmDelete(false)}>No</button>
                        </div>
                    </Modal>
                )}
            </>
        )
    }
    return null;
}

export default TrainingRecLevelContent;