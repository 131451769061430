import React, { useState } from "react";
import { Activity, ChevronDown, ChevronLeft, Edit } from "react-feather";
import styles from '../TrainingRec.module.scss';
import moment from "moment";

const LogItem = ({ log, onEdit, fromView }) => {

    const [view, setView] = useState();

    return (
        <li className={styles.log}>
            <div className='d-flex justify-content-between'>
                <button onClick={() => setView(!view)} className="text-left width-full d-flex align-items-center justify-content-between">
                    <h2 className="pr-2">{log.description}</h2>
                    {view ? <ChevronDown /> : <ChevronLeft />}
                </button>
                <div className={styles.logDuration}>
                    <p className="bold lead">{log.hours} hr</p>
                    <p className="small">{moment(log.date).format('DD MMM YYYY')}</p>
                </div>
            </div>

            {view && (
                <div>
                    <p className="mb-1"><Activity className="text-primary mr-1" />{log.cpdType}</p>
                    <p className="mb-2 newlines">{log.reflection}</p>
                    {!fromView &&
                        <button className="d-flex align-items-center mb-2" onClick={onEdit}><Edit className="mr-1 text-primary" />Edit log</button>
                    }
                </div>
            )}
        </li>
    )
}

export default LogItem;