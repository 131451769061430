import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CREATE_CPD_LOG, EDIT_CPD_LOG, GET_TRAINING_HEADER_INFO } from "./services/graphql";
import FormErrors from "../../../components/FormErrors";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const cpdTypes = [
    "On the job learning / experience",
    "Learning from a case",
    "Self - directed learning",
    "Feedback from a person with lived experience or colleague",
    "Work towards formal qualification or certification",
    "Supervision",
    "Training session",
    "Conference, seminar, workshop or event",
    "Lecturing, teaching, or research",
    "Leadership, management or mentoring",
    "Practitioner Forum"
]

const LogForm = ({ userId, log, close, onAddLog, onEditLog }) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [formErrors, setFormErrors] = useState([]);

    const [createCpdLog, { loading }] = useMutation(CREATE_CPD_LOG, {
        update: (_, { data }) => {
            if (data.createCpdLog?.errors?.length > 0) {
                setFormErrors([]);
                return
            }
            if (data.createCpdLog?.cpdLog?.id) {
                onAddLog(data.createCpdLog?.cpdLog);
                close();
            }
        },
        refetchQueries: [{ query: GET_TRAINING_HEADER_INFO, variables: { id: userId } }]

    });

    const [editCpdLog, { loading: editing }] = useMutation(EDIT_CPD_LOG, {
        update: (_, { data }) => {
            if (data.editCpdLog?.errors?.length > 0) {
                setFormErrors([]);
                return
            }
            if (data.editCpdLog?.cpdLog?.id) {
                onEditLog(data.editCpdLog?.cpdLog);
                close();
            }
        },
        refetchQueries: [{ query: GET_TRAINING_HEADER_INFO, variables: { id: userId } }]
    });

    const onSubmit = (data) => {
        const { date, cpdType, hours, reflection, description } = data;
        const cpdFields = {
            date: moment(date).format('DD/MM/YYYY'),
            cpdType,
            hours: + hours,
            description,
            reflection
        }
        if (log) {
            editCpdLog({
                variables: {
                    ...cpdFields,
                    cpdLogId: log.id,
                }
            });

        } else {
            createCpdLog({
                variables: {
                    ...cpdFields,
                    userId: userId,
                }
            });
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "e" || e.key === "-") {
            e.preventDefault();
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1>{log ? 'Edit' : 'Add'} CPD log</h1>

                <div className="basic-form__group">
                    <p>Date of CPD</p>
                    <Controller
                        control={control}
                        name="date"
                        defaultValue={log?.date ? moment(log.date).toDate() : ''}
                        rules={{
                            validate: value => {
                                if (!value) {
                                    return "Please choose a date";
                                }
                                return true;
                            }
                        }}
                        render={({ field }) => (
                            <ReactDatePicker
                                {...field}
                                dateFormat="dd/MM/yyyy"
                                className="basic-form__text-box picker"
                                placeholderText="Select date"
                                autoComplete="off"
                                maxDate={moment().toDate()}
                                selected={field.value}
                                ref={(ref) => {
                                    if (ref && ref.input) {
                                        field.ref(ref.input);
                                    }
                                }}
                            />
                        )}
                    />
                    {errors.date && <p className="basic-form__hint">{errors.date.message}</p>}
                </div>

                <div className="basic-form__group">
                    <label htmlFor="type">Type</label>
                    <select defaultValue={log?.cpdType} {...register('cpdType', { required: 'Please select' })} className="basic-form__text-select">
                        <option value={''}>Please select</option>
                        {cpdTypes.map((option, i) => <option key={`cptType-${i}`} value={option}>{option}</option>)}
                    </select>
                    {errors.cpdType && <p className="basic-form__hint">{errors.cpdType.message}</p>}
                </div>

                <div className="basic-form__group">
                    <label htmlFor="description">What you did</label>
                    <input defaultValue={log?.description} type="text" {...register('description', { required: 'Please select' })} className="basic-form__text-box" />
                    {errors.description && <p className="basic-form__hint">{errors.description.message}</p>}

                </div>

                <label htmlFor="hours">Number of Hours</label>
                <div style={{ maxWidth: '200px' }} className="basic-form__group flex-row align-items-center">
                    <input
                        step={0.5}
                        min={0.5}
                        name={'hours'}
                        defaultValue={log?.hours}
                        type="number"
                        {...register('hours', { required: 'Please choose duration in hours' })}
                        onKeyDown={handleKeyDown}
                        className={`basic-form__text-box`}
                    /> <span className="ml-1">Hours</span>
                </div>
                {errors.hours && <p className="basic-form__hint">{errors.hours.message}</p>}

                <div className="basic-form__group">
                    <label htmlFor="reflection">Reflection</label>
                    <textarea
                        defaultValue={log?.reflection}
                        {...register('reflection')}
                        className="basic-form__text-box"
                        placeholder={`What you got out of it in terms of CPD?\nHow will it affect your work? (please be reflective)`}
                        rows={8}
                    ></textarea>
                </div>
                {formErrors && <FormErrors errors={formErrors} />}
                <button type="submit" disabled={loading || editing} className="btn btn-primary mr-2">Save</button>
                <button type="button" className="btn btn-outline" onClick={close}>Cancel</button>
            </form>
        </>
    )
}

export default LogForm;