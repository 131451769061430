import React, { useContext } from "react";
import { AuthContext } from "../../../services/auth/AuthProvider";
import Locations from "./Locations";
import TrainingSettings from "./TrainingSettings.js";

const SettingsHome = () => {
    
    const { currentUser } = useContext(AuthContext);
    return currentUser?.organization?.mentoringEnabled ? <Locations /> : <TrainingSettings />;

}

export default SettingsHome;