import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormErrors from "../../../components/FormErrors";
import { useMutation } from "@apollo/client";
import { Paperclip, PlusCircle } from "react-feather";
import moment from "moment";
import { GET_TRAINING_HEADER_INFO } from "../Cpd/services/graphql";
import { GET_TRAINING_REQUIREMENTS } from "./services/graphql";
import DatePicker from 'react-date-picker';
import { ADD_USER_TRAINING_REQUIREMENT } from "./services/graphql";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import styles2 from '../../CaseReports/CaseReports.module.scss';

const TABS = {
    'attended': 1,
    'equivalent': 2
}
const AddRequirement = ({ userId, item, close, userQualifications }) => {

    const [type, setType] = useState(item.externalRequirement ? TABS.equivalent : null);

    const [formErrors, setFormErrors] = useState([]);

    const [showQualificationForm, setShowQualificationForm] = useState(false);
    const [selectedQualification, setSelectedQualification] = useState(null)

    const [addUserTrainingRequirementWithQualification, { loading }] = useMutation(ADD_USER_TRAINING_REQUIREMENT, {
        onCompleted: data => {
            if (data?.addUserTrainingRequirementWithQualification?.errors?.length > 0) {
                setFormErrors(data.addUserTrainingRequirementWithQualification.errors)
                return
            }
            data.addUserTrainingRequirementWithQualification?.userTrainingRequirement?.id && close()
        },
        refetchQueries: [
            { query: GET_TRAINING_REQUIREMENTS, variables: { id: userId, userId: userId } },
            { query: GET_TRAINING_HEADER_INFO, variables: { id: userId } }
        ]
    });

    const saveRequirement = data => {
        const { date, file, name, trainingProvider, level } = data;
        addUserTrainingRequirementWithQualification({
            variables: {
                userId: userId,
                trainingRequirementId: item.id,
                attendedTraining: type === TABS.attended,
                holdsEquivalentQualification: type === TABS.equivalent,
                dateAttended: moment(date).format('YYYY-MM-DD'),
                ...(file[0] && { document: { file: file[0] } }),
                ...(type === TABS.equivalent && {
                    trainingQualificationId: selectedQualification,
                    name: name || null,
                    trainingProvider: trainingProvider || null,
                    level: level || null
                })
            }
        });
    }

    const CourseForm = ({ onSaveIt, close, formErrors, showEquivalent }) => {
        const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
        const selectedDate = watch('date');
        const [selectedFile, setSelectedFile] = useState(null);
        const onSelectFile = (files) => {
            if (files.length) {
                setSelectedFile(files[0]);
            } else {
                setSelectedFile(null)
            }
        }

        const onSubmit = data => {
            onSaveIt(data);
        }

        return (
            <form className="mt-2 mb-2" onSubmit={handleSubmit(onSubmit)}>
                <p>Date of Attendance</p>
                <input type="hidden" {...register('date', { required: 'Please choose a date' })} />
                <DatePicker
                    dayPlaceholder='DD'
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    onChange={(date) => setValue('date', date)}
                    value={selectedDate}
                    className="basic-form__text-box"
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    maxDate={new Date()}
                />
                {errors.date && <p className="basic-form__hint">{errors.date.message}</p>}
                <p>If a multi-day course, please enter the date of the first day</p>
                {showEquivalent && (
                    <>
                        <div className="basic-form--group mt-2">
                            <label htmlFor="name">Course or Qualification Name</label>
                            <input className="basic-form__text-box" type="text" {...register('name', { required: 'Please add name' })} placeholder="e.g. Restorative Practioner Level 1" />
                            {errors.name && <p className="basic-form__hint">{errors.name.message}</p>}
                        </div>

                        <div className="basic-form--group">
                            <label htmlFor="trainingProvider">Training Provider</label>
                            <input className="basic-form__text-box" type="text" {...register('trainingProvider', { required: 'Please add provider' })} placeholder="e.g. College, University or Organisiation Name" />
                            {errors.trainingProvider && <p className="basic-form__hint">{errors.trainingProvider.message}</p>}
                        </div>
                        <div className="basic-form--group mb-2">
                            <label htmlFor="level">Qualification Level</label>
                            <input className="basic-form__text-box" type="text" {...register('level', { required: 'Please add level' })} placeholder="e.g. ONC 2, A-Level, HND" />
                            {errors.level && <p className="basic-form__hint">{errors.level.message}</p>}
                        </div>
                    </>
                )}
                <div style={{ display: selectedFile ? 'none' : 'flex' }} className="basic-form__group relative mb-3 inputCircleContainer">
                    <input
                        {...register('file')}
                        onChange={e => onSelectFile(e.target.files)}
                        type='file'
                        id="file"
                        className={'inputFile'} />
                    <label htmlFor='file'><span className="inputIcon"><Paperclip size='20' className={'buttonIcon'} /></span> Upload certification (optional)</label>
                </div>
                {selectedFile && <p className="mb-2">{selectedFile.name}</p>}
                {formErrors && <FormErrors errors={formErrors} />}
                <button type="submit" disabled={loading} className="btn ">{loading ? 'Saving...' : 'Save'}</button>
                <button type="button" className="btn btn-outline ml-1" onClick={close}>Cancel</button>
            </form>
        )
    }


    return (
        <>
            <h3 className="mb-1">{item.courseTitle}</h3>
            {item.externalRequirement && <p className="mb-1">Requirement: {item.requiredLevel}</p>}

            {!item.externalRequirement &&
                <ul className="list-group mt-2">
                    <li className="d-block list-item list-item--separator-dark py-2">
                        <button className="d-flex align-items-center" onClick={() => setType(TABS.attended)}>
                            <span className={`${styles2.radioSelect} ${type === TABS.attended ? styles2.radioSelectActive : ''}`}></span>
                            I attended this training
                        </button>
                        {type === TABS.attended && <CourseForm formErrors={formErrors} close={close} onSaveIt={(d) => saveRequirement(d)} />}
                    </li>
                    <li className="d-block list-item list-item--separator-dark py-2">
                        <button className="d-flex align-items-center" onClick={() => setType(TABS.equivalent)}>
                            <span className={`${styles2.radioSelect} ${type === TABS.equivalent ? styles2.radioSelectActive : ''}`}></span>
                            I hold an equivalent qualification
                        </button>
                    </li>
                </ul>
            }
            {type === TABS.equivalent && (
                !showQualificationForm ?
                    <div className="mb-2 mt-2">
                        {userQualifications.length > 0 ?
                            <div className={`${item.externalRequirement ?'': 'ml-4'}`}>
                                <p className="mb-2">Select or add a new qualification</p>
                                {userQualifications.map(q => (
                                    <button key={q.id} className="d-flex align-items-center mb-2" onClick={() => setSelectedQualification(q.id)}>
                                        <span className={`${styles2.radioSelect} ${selectedQualification === q.id ? styles2.radioSelectActive : ''}`}></span>
                                        {q.name}
                                    </button>
                                ))}
                            </div> : <p className="mb-2">Add new qualification</p>

                        }
                        <div className={`mt-2 ${item.externalRequirement ? '' : 'ml-4'}`}>
                            {selectedQualification ?
                                <>
                                    <button
                                        type='button'
                                        className="btn"
                                        disabled={loading}
                                        onClick={() => addUserTrainingRequirementWithQualification({
                                            variables: {
                                                holdsEquivalentQualification: true,
                                                userId: userId,
                                                trainingRequirementId: item.id,
                                                trainingQualificationId: selectedQualification
                                            }
                                        })
                                        }>Save</button>
                                    <button className="btn btn-outline ml-2" onClick={close}>Cancel</button>
                                </>
                                :
                                <button className="btn d-flex align-items-center" onClick={() => setShowQualificationForm(true)}><PlusCircle className="mr-1 " size={18} />Add new</button>
                            }
                        </div>
                    </div>
                    :
                    <CourseForm showEquivalent={true} formErrors={formErrors} close={close} onSaveIt={(d) => saveRequirement(d)} />
            )}


        </>
    )
}

export default AddRequirement;