import React, { useState, useEffect, useMemo } from "react";
import { AlertTriangle, Edit } from "react-feather";
import { useForm } from "react-hook-form";
import FormErrors from "../../../components/FormErrors";
import { useMutation } from "@apollo/client";
import { EDIT_MEMBERSHIP, VIEW_USER } from "../services/graphql";
import SideModal from "../../../components/SideModal";
import EditLeaderStatus from "./EditLeaderStatus";
import SaveConfirm from "../../../components/SaveConfirm";
import LevelBadge from "./LevelBadge";

const EditMembership = ({ close, organization, user, currentUser }) => {


    const showFieldsForTrainingRecords = organization.administrativeRegion?.trainingRecordsEnabled;

    const [formErrors, setFormErrors] = useState([]);
    const [editLeader, setEditLeader] = useState(false);

    const [userErrors, setUserErrors] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const defaultOffices = useMemo(() => {
        return organization.offices.map(office => ({
            id: office.id,
            name: office.name,
            isCircleLeader: user.officeMemberships.find(om => om.isCircleLeader && om.office.id === office.id) ? true : false,
            isAdmin: (user.isOrganizationAdmin || user.officeMemberships.find(om => om.isAdmin && om.office.id === office.id)) ? true : false,
            ...showFieldsForTrainingRecords && { isSupervisor: user.officeMemberships.find(om => om.isSupervisor && om.office.id === office.id) ? true : false },
        }));
    }, [organization.offices, user.officeMemberships]);

    const [offices, setOffices] = useState(defaultOffices);

    useEffect(() => {
        setOffices(defaultOffices);
    }, [defaultOffices]);


    const { register, watch, handleSubmit, setValue } = useForm();

    const watchIsOrganizationAdmin = watch('isOrganizationAdmin', user.isOrganizationAdmin);

    const handleOfficeChange = (officeId, field) => {
        setOffices(prevOffices => prevOffices.map(office => office.id === officeId ? ({ ...office, [field]: !office[field] }) : office))
    }

    const handleAllOfficesAdmin = ({ target: { checked } }) => {
        setOffices(prevOffices => prevOffices.map(office => ({ ...office, isAdmin: checked })));
        setValue('isOrganizationAdmin', checked);
    }

    const [editOfficeMemberships, { loading }] = useMutation(EDIT_MEMBERSHIP, {
        onCompleted: data => {
            if (data?.editOfficeMemberships?.errors?.length > 0) {
                setFormErrors(data.editOfficeMemberships.errors)
                return
            }
            if (data.editOfficeMemberships?.user?.id) {
                setShowConfirm(true);
            }
        },
        refetchQueries: [{ query: VIEW_USER, variables: { id: user.id } }]
    });


    const onSubmit = data => {
        const { isOrganizationAdmin } = data;
        const validOffices = offices.filter(office => office.isCircleLeader || office.isAdmin || (showFieldsForTrainingRecords && office.isSupervisor));
        //console.log('data', data, 'valid offices', validOffices);

        // Show error message if NO permissions at all are selected
        if ((offices.filter(o => o.isAdmin || o.isSupervisor).length === 0) && offices.filter(o => o.isCircleLeader).length === 0) {
            setUserErrors(true)
            return
        } else {
            setUserErrors(false);
        }

        const officesData = validOffices.map(office => {
            return {
                officeId: office.id,
                isAdmin: office.isAdmin,
                isCircleLeader: office.isCircleLeader,
                ...showFieldsForTrainingRecords && { isSupervisor: office.isSupervisor }
            }
        });

        editOfficeMemberships({
            variables: {
                userId: user.id,
                isOrganizationAdmin: user.id === currentUser.id ? user.isOrganizationAdmin : isOrganizationAdmin,
                offices: officesData,
            }
        })
    }


    return <>

        <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="mb-3">Permissions</h4>
            <div className="row">
                <div className="col-md">
                    <h4 className="uppercase small bold text-muted-2 underline">Admin access</h4>
                    <div className="basic-form__group basic-form__group--check">
                        <span>Organization Admin </span>
                        <div className="ml-auto d-flex">
                            <input
                                type="checkbox"
                                className="switchToggle"
                                {...register('isOrganizationAdmin')}
                                id='isOrganizationAdmin'
                                onChange={handleAllOfficesAdmin}
                                defaultChecked={user.isOrganizationAdmin}
                                disabled={user.id === currentUser.id} />
                            <label className="switchLabel" htmlFor='isOrganizationAdmin'>&nbsp;</label>
                        </div>
                    </div>
                    <p className="small text-muted mb-2">When on, this person will be able to view and manage all offices</p>
                    <p className="bold mb-2">Office Level Admin</p>
                    <ul className="list-group">
                        {offices.map(office => (
                            <li className="list-item list-item--no-border d-block mb-1" key={`admin-${office.id}`}>
                                <div className="basic-form__group mb-0 basic-form__group--check">
                                    <span>{office.name}</span>
                                    <div className="ml-auto d-flex">
                                        <input
                                            disabled={watchIsOrganizationAdmin === true || user.id === currentUser.id}
                                            type="checkbox"
                                            className="switchToggle"
                                            id={`admin-${office.id}`}
                                            checked={office.isAdmin}
                                            onChange={() => handleOfficeChange(office.id, 'isAdmin')}
                                        />
                                        <label className="switchLabel" htmlFor={`admin-${office.id}`}>&nbsp;</label>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {showFieldsForTrainingRecords &&
                        <>
                            <p className="bold mb-2">Supervisor Access</p>
                            <ul className="list-group">
                                {offices.map(office => (
                                    <li className="list-item  list-item--no-border d-block mb-1" key={`supervisor-${office.id}`}>
                                        <div className="basic-form__group mb-0 basic-form__group--check">
                                            <span>{office.name}</span>
                                            <div className="ml-auto d-flex">
                                                <input
                                                    type="checkbox"
                                                    className="switchToggle"
                                                    id={`supervisor-${office.id}`}
                                                    checked={office.isSupervisor}
                                                    onChange={() => handleOfficeChange(office.id, 'isSupervisor')}
                                                />
                                                <label className="switchLabel" htmlFor={`supervisor-${office.id}`}>&nbsp;</label>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>}
                </div>
                <div className="col-md">
                    <h4 className="uppercase small bold text-muted-2 underline">Circle learder status</h4>
                    <p className="d-flex align-items-center justify-content-between mb-2">
                        <span> Case leader / mentor </span>
                        <span className="d-flex align-items-center">
                            <span className="bold">{offices.some(o => o.isCircleLeader) ? 'ON' : 'OFF'}</span>
                            <button type="button" className="ml-1" onClick={() => setEditLeader(true)}><Edit /></button>
                        </span>
                    </p>

                    {organization.administrativeRegion?.trainingRecordsEnabled && user.practitionerLevel != null &&
                        <LevelBadge className="mb-2" level={user.practitionerLevel} date={user.practitionerLevelDate} />
                    }

                    {offices.filter(o => o.isCircleLeader).length > 0 &&
                        <p className="bold">Can be assigned cases from:</p>
                    }
                    {offices.filter(o => o.isCircleLeader).map(office =>
                        <p key={office.id} className="mb-1">{office.name}</p>
                    )}
                </div>
            </div>

            <div className="d-flex align-items-center">
                <div className="d-flex">
                    <button type="submit" disabled={loading} className="btn btn-fullWidth u-display-block">{loading ? 'Saving...' : 'Save'}</button>
                    <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                </div>
                {userErrors &&
                    <div className="d-flex align-items-center ml-2">
                        <AlertTriangle className="text-danger mr-1" />
                        <div>
                            <p>No permissions</p>
                            {showFieldsForTrainingRecords ? <p className="small">Please select at least one permission to save (Admin, Supervisor, Case Leader).</p> :
                                <p className="small">Please select at least one permission to save (Admin or Case Leader).</p>
                            }
                        </div>
                    </div>
                }
            </div>
            {showConfirm && <SaveConfirm hideConfirm={() => setShowConfirm(false)} />}
            {formErrors && <FormErrors errors={formErrors} />}
        </form>

        {editLeader && (
            <SideModal closeModal={() => setEditLeader(false)}>
                <EditLeaderStatus close={() => setEditLeader(false)} user={user} organization={organization} />
            </SideModal>
        )}
    </>;
}

export default EditMembership;

