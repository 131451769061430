import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";
import FormErrors from "../../../components/FormErrors";
import { GET_TRAINING_HEADER_INFO } from "../Cpd/services/graphql";
import { DECLINE_USER_TR_REQ, DELETE_TRAINING_REQ_OR_QUALIFICATION, EDIT_TRAINING_REQ_QUALIFICATION, GET_TRAINING_REQUIREMENTS, REINSTATE_USER_TR_REQ } from "./services/graphql";
import { AlertTriangle, Calendar, Download, Edit, Loader, Paperclip, Slash, Star, Trash } from "react-feather";
import axios from "axios";
import { USER_TR_STATUS } from "../../../utils/constants";
import { useForm } from "react-hook-form";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';

const MatchItem = ({ userId, match, close, fromView, currentMatch }) => {

    const [showEditInline, setShowEditInline] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [reinstateErrors, setReinstateErrors] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            date: match?.trainingQualification?.dateAttended || match?.dateAttended
        }
    });

    const onSelectFile = (files) => files.length ? setSelectedFile(files[0]) : setSelectedFile(null);

    const selectedDate = watch('date',);

    const onDownloadFile = (url) => {
        axios({
            method: 'get',
            url: url,
            responseType: 'blob'
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = downloadUrl;

            // Extract the filename from the Content-Disposition header or use a default name
            const contentDisposition = response.headers['content-disposition'];
            let filename = url.split('/').pop();

            if (contentDisposition) {
                const matches = /filename="?(.+)"?/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1];
                }
            }
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        }).catch((error) => {
            console.error('Failed to download file:', error);
        });
    }

    const queriesToRefetch = () => {
        return [
            { query: GET_TRAINING_REQUIREMENTS, variables: { id: userId, userId: userId } },
            { query: GET_TRAINING_HEADER_INFO, variables: { id: userId } }
        ]
    }
    const [deleteTrainingRequirementOrQualification, { loading: deleting }] = useMutation(DELETE_TRAINING_REQ_OR_QUALIFICATION, {
        onCompleted: data => {
            if (data?.deleteTrainingRequirementOrQualification?.errors?.length > 0) {
                setFormErrors(data.deleteTrainingRequirementOrQualification.errors)
                return
            }
            data.reinstateUserTrainingRequirement?.userTrainingRequirement?.id && close();
        },
        refetchQueries: () => queriesToRefetch()
    });

    const [editTrainingRequirementOrQualification, { loading: editing }] = useMutation(EDIT_TRAINING_REQ_QUALIFICATION, {
        onCompleted: data => {
            if (data?.editTrainingRequirementOrQualification?.errors?.length > 0) {
                setFormErrors(data.editTrainingRequirementOrQualification.errors)
                return
            }
            if (data.editTrainingRequirementOrQualification?.userTrainingRequirement?.id) {
                setShowEditInline(false);
                setSelectedFile(null);
            }
        },
        refetchQueries: () => queriesToRefetch()
    });

    const [reinstateUserTrainingRequirement, { loading: reinstating }] = useMutation(REINSTATE_USER_TR_REQ, {
        onCompleted: data => {
            if (data?.reinstateUserTrainingRequirement?.errors?.length > 0) {
                setReinstateErrors(data.reinstateUserTrainingRequirement.errors)
                return
            }
            if (data.reinstateUserTrainingRequirement?.userTrainingRequirement?.id) {
                setReinstateErrors([]);
                close();
            } 
        },
        refetchQueries: () => queriesToRefetch()
    });

    const [declineUserTrainingRequirement, { loading: declining }] = useMutation(DECLINE_USER_TR_REQ, {
        onCompleted: data => {
            if (data?.declineUserTrainingRequirement?.errors?.length > 0) {
                setReinstateErrors(data.declineUserTrainingRequirement.errors)
                return
            }
            data.reinstateUserTrainingRequirement?.userTrainingRequirement?.id && close();
        },
        refetchQueries: () => queriesToRefetch()
    });

    const onSubmit = data => {
        const { date, file } = data;
        editTrainingRequirementOrQualification({
            variables: {
                userTrainingRequirementId: match?.id,
                dateAttended: moment(date).format('YYYY-MM-DD'),
                ...selectedFile && {
                    document: {
                        file: file[0]
                    }
                }
            }
        });
    }

    return (
        <div className={`bg-primary-light p-2 radius-2 mb-2 ${match.status === USER_TR_STATUS.declined ? 'bg-gray' : ''}`}>
            <div className="d-flex justify-content-between">
                <div className="main">
                    <p className="mb-2">{match.holdsEquivalentQualification ? "Equivalent Qualification" : "Attended event"}</p>
                    {confirmDelete && (
                        <div className="mb-2">
                            <p className="bold">Are you sure?</p>
                            <button disabled={deleting} className="mr-1 btn" onClick={() => deleteTrainingRequirementOrQualification({
                                variables: {
                                    userTrainingRequirementId: match.id
                                }
                            })}>Yes</button>
                            <button className="btn btn-outline" onClick={() => setConfirmDelete(false)}>No</button>
                            {formErrors && <FormErrors errors={formErrors} />}
                        </div>
                    )}

                    {showEditInline ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <p>Date of Attendance</p>
                            <input type="hidden" {...register('date')} />
                            <DatePicker
                                dayPlaceholder='DD'
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                onChange={(date) => setValue('date', date)}
                                value={selectedDate}
                                className="basic-form__text-box"
                                format="dd/MM/yyyy"
                                clearIcon={null}
                                maxDate={new Date()}
                            />
                            {errors.date && <p className="basic-form__hint">{errors.date.message}</p>}

                            <div style={{ display: selectedFile ? 'none' : 'flex' }} className="basic-form__group relative mb-3 inputCircleContainer">
                                <input
                                    {...register('file')}
                                    onChange={e => onSelectFile(e.target.files)}
                                    type='file'
                                    id="file"
                                    className={'inputFile'} />
                                <label htmlFor='file'><span className="inputIcon"><Paperclip size='20' className={'buttonIcon'} /></span> Upload certification (optional)</label>
                            </div>
                            {selectedFile && <p className="mb-2">{selectedFile.name}</p>}
                            {formErrors && <FormErrors errors={formErrors} />}
                            <button type="submit" disabled={editing} className="btn ">{editing ? 'Saving...' : 'Save'}</button>
                            <button type="button" className="btn btn-outline ml-1" onClick={() => setShowEditInline(false)}>Cancel</button>
                        </form> :

                        <>
                            {match.holdsEquivalentQualification ?
                                <>
                                    {match.trainingQualification.dateAttended && <p className="d-flex align-items-center mb-1"><Calendar className="text-primary mr-1" /> {moment(new Date(match.trainingQualification.dateAttended)).format('DD MMM YYYY')}</p>}

                                    <p>{match.trainingQualification?.name} <span className="ml-4">{match.trainingQualification?.level}</span></p>
                                    <p>{match.trainingQualification?.trainingProvider}</p>
                                    {match.trainingQualification?.documents?.length > 0 && <p><button className="d-flex align-items-center" onClick={() => onDownloadFile(match.trainingQualification.documents[0].url)}><Download className="text-primary mr-1" /> Download / view certificate</button></p>}
                                </> :
                                <>
                                    <p className="d-flex align-items-center mb-1"><Calendar className="text-primary mr-1" /> {moment(new Date(match.dateAttended)).format('DD MMM YYYY')}</p>

                                    <p>{match.courseTitle}</p>
                                    {match.documents?.length > 0 && <p><button className="d-flex align-items-center" onClick={() => onDownloadFile(match.documents[0].url)}><Download className="text-primary mr-1" /> Download / view certificate</button></p>}
                                </>
                            }
                        </>
                    }
                </div>
                <div className="side">
                    {fromView && currentMatch?.id === match.id &&
                        <button
                            disabled={declining}
                            className="d-flex align-items-center btn"
                            onClick={() => declineUserTrainingRequirement({
                                variables: {
                                    userTrainingRequirementId: match.id
                                }
                            })}
                        >{declining ? <><Loader className="mr-1" /> Saving </> : <><Slash className="mr-1" /> Decline</>}</button>
                    }

                    {!fromView &&
                        <>
                            {currentMatch ?
                                <div className="text-right">
                                    <button onClick={() => setShowEditInline(true)}><Edit /></button>
                                    <button className="ml-2" onClick={() => setConfirmDelete(true)}><Trash className="text-danger" /></button>
                                </div> : match.status !== USER_TR_STATUS.declined &&
                                <>
                                    <button
                                        disabled={reinstating}
                                        onClick={() => reinstateUserTrainingRequirement({
                                            variables: {
                                                userTrainingRequirementId: match.id
                                            }
                                        })} className="btn btn-primary d-flex align-items-center"
                                    >{reinstating ? <><Loader />Saving... </> : <><Star className="mr-1" />Set as current</>}</button>
                                    {reinstateErrors && <FormErrors errors={reinstateErrors} />}
                                </>
                            }
                        </>
                    }

                    {
                        match.status === USER_TR_STATUS.declined &&
                        <div className="text-right">
                            <p className="lead d-flex align-items-center justify-content-end mb-1"><AlertTriangle className="mr-1" /> Declined</p>
                            {match.declinedBy && <p>By: {match.declinedBy.firstName} {match.declinedBy.lastMame}</p>}
                            {match.declineDate && <p className="mb-1">{moment(new Date(match.declineDate)).format('DD MMM YYYY')} @{moment(new Date(match.declineDate)).format('HH:mm')}</p>}
                            {reinstateErrors && <FormErrors errors={reinstateErrors} />}
                            {!currentMatch &&
                                <button
                                    disabled={reinstating}
                                    onClick={() => reinstateUserTrainingRequirement({
                                        variables: {
                                            userTrainingRequirementId: match.id
                                        }
                                    })}
                                >{reinstating ? 'Saving...' : 're-instate'}</button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MatchItem;