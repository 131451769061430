import React, { useState, useEffect, useMemo, useContext } from "react";
import { AlertTriangle } from "react-feather";
import { useForm } from "react-hook-form";
import FormErrors from "../../../components/FormErrors";
import { useMutation } from "@apollo/client";
import { EDIT_MEMBERSHIP, VIEW_USER, UPDATE_PRACTITIONER_LEVEL } from "../services/graphql";
import moment from "moment";
import { AuthContext } from "../../../services/auth/AuthProvider";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const EditLeaderStatus = ({ close, organization, user }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const showFieldsForTrainingRecords = organization.administrativeRegion?.trainingRecordsEnabled;

    const [formErrors, setFormErrors] = useState([]);
    const [userErrors, setUserErrors] = useState(null);

    const defaultOffices = useMemo(() => {
        return organization.offices.map(office => ({
            id: office.id,
            name: office.name,
            isAdmin: (user.isOrganizationAdmin || user.officeMemberships.find(om => om.isAdmin && om.office.id === office.id)) ? true : false,
            ...showFieldsForTrainingRecords && { isSupervisor: user.officeMemberships.find(om => om.isSupervisor && om.office.id === office.id) ? true : false },
            isCircleLeader: user.officeMemberships.find(om => om.isCircleLeader && om.office.id === office.id) ? true : false,
        }));
    }, [organization.offices, user.officeMemberships]);

    const [offices, setOffices] = useState(defaultOffices);

    useEffect(() => {
        setOffices(defaultOffices);
    }, [defaultOffices]);


    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();

    useEffect(() => {
        setValue('isLeader', offices.some(o => o.isCircleLeader));
    }, [offices, setValue]);

    const watchIsLeader = watch('isLeader');

    const handleOfficeChange = (officeId, field) => {
        setOffices(prevOffices => prevOffices.map(office => office.id === officeId ? ({ ...office, [field]: !office[field] }) : office))
    }

    const handleAllLeaderOffices = ({ target: { checked } }) => {
        setOffices(prevOffices => prevOffices.map(office => ({ ...office, isCircleLeader: checked })));
        setValue('isLeader', checked);
    }


    const [editOfficeMemberships, { loading }] = useMutation(EDIT_MEMBERSHIP, {
        onCompleted: data => {
            if (data?.editOfficeMemberships?.errors?.length > 0) {
                setFormErrors(data.editOfficeMemberships.errors)
                return
            }
            data.editOfficeMemberships?.user?.id && close()
        },
        refetchQueries: [{ query: VIEW_USER, variables: { id: user.id } }]
    });

    const [updatePractitionerLevel, { loading: updatingLevel }] = useMutation(UPDATE_PRACTITIONER_LEVEL, {
        onCompleted: data => {
            if (data?.updatePractitionerLevel?.errors?.length > 0) {
                setFormErrors(data.updatePractitionerLevel.errors)
                return
            }
            if (data.updatePractitionerLevel?.user?.id) {
                const updatedUser = data.updatePractitionerLevel?.user
                const cu = { ...currentUser, practitionerLevel: updatedUser.practitionerLevel, practitionerLevelDate: updatedUser.practitionerLevelDate }
                setCurrentUser(cu);
                close();
            }
        },
        refetchQueries: [{ query: VIEW_USER, variables: { id: user.id } }]
    });

    const selectedDate = watch('date', user.practitionerLevelDate);


    const onSubmit = data => {
        const { isLeader, level, date } = data;
        
        const validOffices = offices.filter(office => office.isCircleLeader || office.isAdmin || (showFieldsForTrainingRecords && office.isSupervisor));
        // console.log('validOffices', validOffices);

        if ((offices.filter(o => o.isAdmin || o.isSupervisor).length === 0) && offices.filter(o => o.isCircleLeader).length === 0) {
            setUserErrors(true)
            return
        } else {
            setUserErrors(false);
        }

        const officesData = validOffices.map(office => {
            return {
                officeId: office.id,
                isCircleLeader: office.isCircleLeader,
                isAdmin: office.isAdmin,
                ...showFieldsForTrainingRecords && { isSupervisor: office.isSupervisor }
            }
        });
        if (showFieldsForTrainingRecords && isLeader) {
            updatePractitionerLevel({
                variables: {
                    userId: user.id,
                    practitionerLevel: +level,
                    practitionerLevelDate: moment(date).format(),
                    offices: officesData,
                }
            });
        } else {
            editOfficeMemberships({
                variables: {
                    userId: user.id,
                    isOrganizationAdmin: user.isOrganizationAdmin,
                    offices: officesData,
                }
            });
        }
    }


    return <>
        <div className="row">
            <div className="col-md-8">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic-form__group basic-form__group--check">
                        <span>Qualified case leader </span>
                        <div className="ml-auto d-flex">
                            <input
                                type="checkbox"
                                className="switchToggle"
                                {...register('isLeader')}
                                id='isLeader'
                                checked={offices.some(o => o.isCircleLeader)}
                                onChange={handleAllLeaderOffices} />
                            <label className="switchLabel" htmlFor='isLeader'>&nbsp;</label>
                        </div>
                    </div>
                    {watchIsLeader && showFieldsForTrainingRecords &&
                        <>
                            <div className="mb-2">
                                <label>Practitioner level</label>
                                <select
                                    {...register('level', { required: 'Please select an option' })}
                                    className="basic-form__text-select"
                                    defaultValue={user?.practitionerLevel}
                                >
                                    <option value="">Select level</option>
                                    <option value={0}>Trainee</option>
                                    <option value={1}>Foundation</option>
                                    <option value={2}>Intermediate</option>
                                    <option value={3}>Advanced</option>
                                </select>
                                {errors.level && <p className="basic-form__hint">{errors.level.message}</p>}
                            </div>

                            <div className="mb-2">
                                <label>Date Acquired</label>
                                <input defaultValue={user.practitionerLevelDate} type="hidden" {...register('date')} />
                                <DatePicker
                                    onChange={(date) => setValue('date', date)}
                                    value={selectedDate}
                                    className="basic-form__text-box d-block width-full"
                                    format="dd/MM/yyyy"
                                    clearIcon={null}
                                    maxDate={new Date()}
                                />
                                {errors.date && <p className="basic-form__hint">{errors.date.message}</p>}
                            </div>
                        </>
                    }
                    <div style={{ display: offices.find(o => o.isCircleLeader) ? 'block' : 'none' }}>
                        <p className="bold mb-2">Can be assigned cases from:</p>
                        <ul className="list-group">
                            {offices.map(office => (
                                <li className="list-item d-block mb-2" key={`leader-${office.id}`}>
                                    <div className="basic-form__group basic-form__group--check">
                                        <span>{office.name}</span>
                                        <div className="ml-auto d-flex">
                                            <input
                                                type="checkbox"
                                                className="switchToggle"
                                                id={`leader-${office.id}`}
                                                checked={office.isCircleLeader}
                                                onChange={() => handleOfficeChange(office.id, 'isCircleLeader')}
                                            />
                                            <label className="switchLabel" htmlFor={`leader-${office.id}`}>&nbsp;</label>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="d-flex mb-2">
                        <button type="submit" disabled={loading || updatingLevel} className="btn btn-fullWidth u-display-block">{loading ? 'Saving...' : 'Save changes'}</button>
                        <button type="button" onClick={close} className="btn btn-muted ml-2">Cancel</button>
                    </div>
                    {userErrors &&
                        <div className="d-flex align-items-center ml-2">
                            <AlertTriangle size={34} className="text-danger mr-1" />
                            <div>
                                <p>User has no permissions left</p>
                                {showFieldsForTrainingRecords ? <p className="small">Please select at least one permission to save (Admin, Supervisor, Case Leader).</p> :
                                    <p className="small">Please select at least one permission to save (Admin, Case Leader).</p>
                                }
                            </div>
                        </div>
                    }
                    {formErrors && <FormErrors errors={formErrors} />}
                </form>
            </div>
        </div >
    </>;
}

export default EditLeaderStatus;

