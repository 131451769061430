import React, { useContext } from "react";
import LeaderItem from "./components/LeaderItem";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../../services/auth/AuthProvider";
import { PRACTITIONER_COMPLIANCE } from "./services/graphql";
import { GET_TRAINING_REQUIREMENTS } from "../Settings/services/graphql";
import Loader from '../../components/Loader';
import { TR_REQUIREMENT_LEVELS } from "../../utils/constants";
import styles from './PractitionerCompliance.module.scss';

const levels = ['F', 'I', 'A'];

const PractitionerCompliance = ({ officeId }) => {

    const { currentUser } = useContext(AuthContext);

    const { data, error, loading } = useQuery(PRACTITIONER_COMPLIANCE, {
        fetchPolicy: 'network-only',
        variables: {
            ...!officeId && { organizationId: currentUser?.organization.id },
            ...officeId && { officeId },
        }
    });

    const { data: dataTraining, loading: loadingTraining } = useQuery(GET_TRAINING_REQUIREMENTS, {
        variables: {
            organizationId: currentUser?.organization?.id
        }
    });






    if (loading || loadingTraining) return <Loader />
    if (error) return  <p>{error.message}</p>
    if (data?.practitionerComplianceLeaders && dataTraining) {

        const leaders = data.practitionerComplianceLeaders || []

        const trainingRequirements = dataTraining.organization.trainingRequirements || [];

        const globalStats = (label) => {
            return trainingRequirements.reduce(
                (acc, elem) => {
                    if (elem[label] === TR_REQUIREMENT_LEVELS.required) acc.required++;
                    if (elem[label] === TR_REQUIREMENT_LEVELS.desired) acc.desired++
                    return acc;
                },
                { required: 0, desired: 0 }, // accumulator initial
            )

        }
        return (
            <>
                <ul>
                    <li className={styles.header}>
                        <div className={styles.headerName}></div>
                        <div className={styles.headerStats}>
                            <div className={styles.itemHeader}>
                                <p className="bold">Pr. levels</p>
                            </div>
                            <div className={`${styles.itemHeader} mr-2`}>
                                <p className="bold">CPD Hours</p>
                            </div>
                            {trainingRequirements.length > 0 && levels.map(level =>
                                <div key={level} className={styles.itemHeader}>
                                    <p className="bold">{level}</p>
                                </div>
                            )}
                            <div className={styles.itemHeader + ' ' + styles.itemCtc}>
                                <p className="bold">CTC</p>
                            </div>
                        </div>

                    </li>
                    {leaders.map(leader => <LeaderItem key={leader.id} globalStats={globalStats} trainingRequirements={trainingRequirements} leader={leader} />)}
                </ul>
            </>
        )
    }
    return null;
}

export default PractitionerCompliance;