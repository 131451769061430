import { gql } from "@apollo/client";

export const ADD_MEMBER = gql`
mutation addMember($firstName: String, $lastName: String, $email: String) {
    addMember(firstName: $firstName, lastName: $lastName, email: $email) {
        id
        firstName
    }
}
`;

export const VIEW_USER = gql`
query user($id: ID!) {
    user(id: $id) {
        id
        active
        firstName
        lastName
        email
        isOrganizationAdmin
        photoUrl
        practitionerLevel
        practitionerLevelDate
        userProfile {
            id
            rjcMember
            rjcRegisteredStatus
            rjcRegisteredStatusName
            biography
            backgroundChecks {
                id
                ctcStatus
                ctcCheckDate
                ctcReferenceNumber
                dbsCheckDate
                dbsCertificateNumber
            }
            employmentStatus
            areaOfPractice
            experiences {
                id
                name
                categoryType
            }
            qualifications {
                id
                name
                categoryType
            }
        }
        officeMemberships {
            id
            isAdmin
            isCircleLeader
            isSupervisor
            office {
                id
                name
            }
        }
        offices {
            id
            name
        }
    }
}
`;

export const GET_ORG_USERS = gql`
query organization($organizationId: ID!, $showAllUsers: Boolean!) {
    organization(organizationId: $organizationId) {
        id
        officeAdmins {
            id
            firstName
            lastName
            active
        }
        offices {
            id
            name
        }
        administrativeRegion {
            id
            name
            countryCode
            trainingRecordsEnabled
        }
        adminsAndLeaders(showAllUsers: $showAllUsers) {
            id
            active
            firstName
            lastName
            email
            isOrganizationAdmin
            photoUrl
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                isSupervisor
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;

export const GET_OFFICE_USERS = gql`
query office($officeId: ID!) {
    office(officeId: $officeId) {
        id
        users {
            id
            active
            firstName
            lastName
            email
            isOrganizationAdmin
            photoUrl
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                isSupervisor
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;

export const CREATE_USER_ASSIGN_TO_OFFICES = gql`
mutation createUserAndAssignToOffices($organizationId: ID!, $firstName: String!, $lastName: String!, $email: String!, $photoUrl: String, $isOrganizationAdmin: Boolean!, $offices: [OfficeInput!], $addPractitionerLevel: Boolean, $practitionerLevel: Int, $practitionerLevelDate: String) {
    createUserAndAssignToOffices(organizationId: $organizationId, firstName: $firstName, lastName: $lastName, email: $email, photoUrl: $photoUrl, isOrganizationAdmin: $isOrganizationAdmin, offices: $offices, addPractitionerLevel: $addPractitionerLevel, practitionerLevel: $practitionerLevel, practitionerLevelDate: $practitionerLevelDate) {
        errors
        user {
            id
            firstName
            lastName
            email
            photoUrl
            isOrganizationAdmin
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;

export const EDIT_MEMBERSHIP = gql`
mutation editOfficeMemberships($userId: ID!,  $isOrganizationAdmin: Boolean!, $offices: [OfficeInput!]!) {
    editOfficeMemberships(userId: $userId, isOrganizationAdmin: $isOrganizationAdmin,  offices: $offices) {
        errors
        user {
            id
            firstName
            lastName
            email
            photoUrl
            isOrganizationAdmin
            officeMemberships {
                id
                isAdmin
                isCircleLeader
                isSupervisor
                office {
                    id
                    name
                }
            }
            offices {
                id
                name
            }
        }
    }
}
`;

export const EDIT_LEADER_PRACTICE = gql`
mutation updateLeaderProfileEmploymentAndAreaOfPractice($userId: ID!, $employmentStatus: String!, $areaOfPractice: String!) {
    updateLeaderProfileEmploymentAndAreaOfPractice(userId: $userId, employmentStatus: $employmentStatus, areaOfPractice: $areaOfPractice) {
        errors
        userProfile {
            id
        }
    }
}
`;

export const QUALIFICATIONS_EXPERIENCES = gql`
query qualificationsAndExperiences {
    qualificationsAndExperiences {
        experiences {
            id
            categoryType
            name
        }
        qualifications {
            id
            categoryType
            name
        }
    }
}
`;

export const EDIT_QUALIFICATIONS_EXPERIENCES = gql`
mutation updateProfileDbsQualificationsExperiences($userId:ID!, $qualifications: [QualificationsExperienceInput!], $experiences: [QualificationsExperienceInput!]) {
    updateProfileDbsQualificationsExperiences(userId: $userId, qualifications: $qualifications, experiences: $experiences) {
        errors
        userProfile {
            id
        }
    }
}
`;

export const ADD_BACKGROUND_CHECK = gql`
mutation addBackgroundCheck($userProfileId: ID!, $ctcStatus: Int, $ctcCheckDate: String, $ctcReferenceNumber: String) {
    addBackgroundCheck(userProfileId: $userProfileId, ctcStatus: $ctcStatus, ctcCheckDate: $ctcCheckDate, ctcReferenceNumber: $ctcReferenceNumber) {
        errors
        backgroundCheck {
            id
            ctcCheckDate
            ctcReferenceNumber
            ctcStatus
        }
    }
}
`;

export const UPDATE_PRACTITIONER_LEVEL = gql`
mutation updatePractitionerLevel($userId: ID!, $practitionerLevel: Int!, $practitionerLevelDate: String!, $offices: [OfficeInput!]) {
    updatePractitionerLevel(userId: $userId, practitionerLevel: $practitionerLevel, practitionerLevelDate: $practitionerLevelDate, offices: $offices) {
        errors
        user {
            id
            practitionerLevel
            practitionerLevelDate
        }
    }
}
`;